import { Market, Sport, Tournament, MyEvent, Media, IChat, IChatHistory, IBBAndVS, DefaulFilters } from "interfaces/interfaces";
import { createContext } from "react";
import { generateUUID } from "utils/general";

export interface IStettingsState {
    isLeftNavOpen: boolean;
    isRightNavOpen: boolean;
    inputValToSave: string;
    inputValToRename: string;
    inputIdToRename: string | null;
    promptToRename: string | null;
    isOpenSaveModal: boolean;
    isOpenRenameModal: boolean;
    isOpenDeleteModal: boolean;
    chatToDelete: number | null;
    promptPlaceholder: string;
    isOpenInfoModal: boolean;
    infoModalText: string;
    isOpenVarsModal: boolean;
    activeDropdown: string | null;
    isPromptFocus: boolean;
    // isOverLimit: boolean;
    // isOverLimitGPT4: boolean;
    isGPT4switchActive: boolean;
    isGPT3_5switchActive: boolean;
    isSmallScreen: boolean;
    // isShownErrorPage: boolean;
    isRegistrationDisabled: boolean;
    isOpenGPTSettingsModal: boolean;
    isAvailableGPTChange: boolean;
    gptModels: string[];
    gptModel: string;
    isConnectionLost: boolean;
    // isFeedbackNudgingOpen: boolean;
}

export interface ContextProps {
    appState: IAppContext;
    setAppState: any;
    settingsState: any;
    setSettingsState: any;
    trackContext: ITrackContext;
    setTrackContext: any;
}

export const initialSettingsState: IStettingsState = {
    isLeftNavOpen: true,
    isRightNavOpen: true,
    inputValToSave: "",
    inputValToRename: "",
    inputIdToRename: null,
    promptToRename: null,
    isOpenSaveModal: false,
    isOpenRenameModal: false,
    isOpenDeleteModal: false,
    chatToDelete: null,
    isOpenInfoModal: false,
    infoModalText: "",
    isOpenVarsModal: false,
    promptPlaceholder: `Write an engaging facebook post for the match.
The post should introduce the match and predict its development. 
The text may only contain a maximum of 3 numerical figures, and the language should be energetic. 
There must be nothing about odds, bookmakers or nicknames.
The text must be in English and in third person.
The text must be designed as a Facebook post. The headline no more than 40 characters. The body text no more than 160 characters, use emojis.`,
    activeDropdown: null,
    isPromptFocus: false,
    // isOverLimit: false,
    // isOverLimitGPT4: false,
    isGPT4switchActive: false,
    isGPT3_5switchActive: false,
    isSmallScreen: false,
    // isShownErrorPage: false,
    isRegistrationDisabled: false,
    isOpenGPTSettingsModal: false,
    isAvailableGPTChange: false,
    gptModels: [],
    gptModel: '',
    isConnectionLost: false,
    // isFeedbackNudgingOpen: false,
}

export interface IAppContext {
    market: Market;
    markets: Market[];
    sport: Sport;
    tournament: Tournament;
    myEvent: MyEvent;
    media: Media;
    chat: IChat;
    bbAndVsData: IBBAndVS;
    output: string;
    prompt: string;
    chatsHistory: IChatHistory[] | [];
    defaultFilters: DefaulFilters | null;
    sportsContext: string;
   }
  
export const initialMainState: IAppContext = {
    market: { id: null,name: null},
    markets: [],
    sport: {id: null, name: null},
    tournament: {id: null, name: null},
    myEvent: {id: null, name: null},
    media: {id: null, name: null},
    chat: {id: null, name: null},
    bbAndVsData: {
        vs: null,
        bb: null
    },
    output: "",
    prompt: "",
    chatsHistory: [],
    defaultFilters: {
        sport: null,
        tournament: null,
        myEvent: null,
        market: null,
    },
    sportsContext: ``,

}

export interface ITrackContext {
    context_id: string;
}

export const initialTrackContext: ITrackContext = {
    context_id: generateUUID(),
}

export const appContext = createContext<ContextProps | null>(null);