import React, { useContext, useEffect, useState } from 'react';
import SPDropdownDiv from 'components/StatusPage/SPLeftNav/SPDropdownDiv';
import { SportFP, TournamentFP, MyEventFP, Sport } from 'interfaces/interfaces';
import Spinner from 'components/common/Modals/modalParts/Spinner';
import { getLeagueStatus, getMarkets, getMyEvents, getSports, getTournaments } from 'api/functions';
import { convertStringArrayToObject } from 'utils/general';
import { IAppContext, IStettingsState, appContext } from 'state/statusPageContext';
import { FILTER_TYPES } from 'enums/filterTypes';
import { ReactComponent as CloseButton } from 'assets/svg/closeButton.svg';
import { MAX_WIDTH_SMALL_SCREEN } from 'enums/others';

const HARDCODED_SPORT_FOOTBALL = {
  "name": "football",
  "id": 1000093190
}

type League = {
  status: string;
  name: string;
};

type Country = {
  leagues: League[];
  name: string;
};

const SPLeftNav  = () => { 
  const ctx = useContext(appContext);
  const [sports, setSports ] = useState<SportFP[]>([HARDCODED_SPORT_FOOTBALL]);
  const [sport, setSport ] = useState<SportFP>(HARDCODED_SPORT_FOOTBALL)
  const [tournaments, setTournaments ] = useState<TournamentFP[]>();
  const [tournament, setTournament ] = useState<TournamentFP>()
  const [myEvents, setMyEvents ] = useState<MyEventFP[]>(); 
  const [myEvent, setMyEvent ] = useState<MyEventFP>() 
  const [isLoadingMyEvents, setIsLoadingMyEvents ] = useState<boolean>(false)
  const [isLoadingTournaments, setIsLoadingTournaments ] = useState<boolean>(false)
  const [isLoadingSports, setIsLoadingSports ] = useState<boolean>(false)
  const [markets, setMarkets] = useState<any[]>();
  const [market, setMarket] = useState<any>();
  const [isLoadingMarkets, setIsLoadingMarkets ] = useState<boolean>(false)
  // const [isLoadingLeaguesStatus, setIsLoadingLeaguesStatus] = useState<boolean>(false)
  const [leaguesSt, setLeaguesStatus] = useState<any[]>([]);
  const { setSettingsState, setAppState} = ctx || {};
  const { isLeftNavOpen, isSmallScreen, isLoadingLeaguesStatus } = ctx?.settingsState || {};
  const {market: appStateMarket, sport: appStateSport,
        tournament: appStateTournament, defaultFilters, 
        markets: appStateMarkets} = ctx?.appState || {};


  const closeNav = () => {
    setSettingsState((prev: IStettingsState) => ({
      ...prev,
      isLeftNavOpen: false,
    }))
  }

  const isTournamentEmptyArray = async (sport: Sport): Promise<boolean> => {
    if (appStateMarket?.name && sport.id) {
      const tournament = await getTournaments(appStateMarket?.name, sport.id);
      if (!tournament || tournament.length === 0) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return false;
    }
  }

  const Filters = 
    <>
      {(markets || appStateMarkets )&& !isLoadingMarkets ?
      <SPDropdownDiv
        title={FILTER_TYPES.MARKET}
        options={markets ?? appStateMarkets}
        setValue={setMarket}
        defaultItem={defaultFilters?.market}
        />
      :
      <SPDropdownDiv title={FILTER_TYPES.MARKET} disabled={true} nav={true} />}
    {isLoadingMarkets ? <div data-tesid={`spinner-${FILTER_TYPES.MARKET}`} className='self-start ml-5 mt-0 mb-2'><Spinner /></div> : <div className='self-start mt-0 mb-3' />}

    {sports && !isLoadingSports ?
      <SPDropdownDiv
        title={FILTER_TYPES.SPORT}
        options={sports}
        setValue={setSport}
        disabled={sports?.length === 0 || !sports}
        defaultItem={defaultFilters?.sport}
      />
      :
      <SPDropdownDiv title={FILTER_TYPES.SPORT} disabled={true} />}
    {isLoadingSports ? <div data-tesid={`spinner-${FILTER_TYPES.SPORT}`} className='self-start ml-5 mt-0 mb-2'><Spinner /></div> : <div className='self-start mt-0 mb-3' />}
  </>

  const FiltersContent =
    <div className="flex-grow overflow-auto custom-scrollbar relative top-0 h-full">
      {isSmallScreen ? (
         <div className={`flex flex-col items-center justify-center mt-[59px]`}>
          {Filters}
       </div>
      ) : (
        <div className={`flex flex-col items-center justify-center mt-[159px]`}>
          {Filters}
      </div>
      )}
    </div>

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        if (appStateMarket?.name === null) {
          setIsLoadingMarkets(true);
          const data = await getMarkets();
          if (data) {
            const convertedArray = convertStringArrayToObject(data);
            setMarkets(convertedArray);
            setAppState((prevState: IAppContext) => ({
              ...prevState,
              markets: convertedArray
            }));
          } else {
            console.log('getMarkets Error');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingMarkets(false);
      }
    };
  
    fetchMarkets();
  }, [appStateMarket]);

  useEffect(() => {
    if (market) {
      localStorage.setItem('default_market', JSON.stringify(market));
      setAppState((prevState: any) => ({
        ...prevState,
        market: market 
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market]);

  // useEffect(() => {
  //   const fetchSports = async () => {
  //     try {
  //       setIsLoadingSports(true);
  //       const marketName = appStateMarket?.name;
  //       if (marketName) {
  //         const data = await getSports(marketName);
  //         if (data) {
  //           console.log('sports', data);
  //           const newSports = await Promise.all(data.map(async (sport: Sport) => {
  //             const isTournamentEmpty = await isTournamentEmptyArray(sport);
  //             if (isTournamentEmpty) {
  //               return {
  //                 ...sport,
  //                 name: sport.name + ' (no current data)'
  //               };
  //             } else {
  //               return sport;
  //             }
  //           }));
  //           setSports(newSports);
  //         } else {
  //           console.log('getSports Error');
  //         }
  //       } else {
  //         setSports([]);
  //       }
  //     } catch (error) {
  //       console.error('getSports', error);
  //     } finally {
  //       setIsLoadingSports(false);
  //     }
  //   };
  
  //   fetchSports();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appStateMarket]);

  useEffect(() => {
    setAppState((prevState: any) => ({
        ...prevState,
        sport: sport
    }));

    

    const fetchLeaguesStatus = async () => {

      try {
        setSettingsState((prevState: IStettingsState) => ({
          ...prevState,
          isLoadingLeaguesStatus: true
        }));
        // if (appStateMarket && appStateMarket?.name && appStateSport?.id) {
          if (market && sport) {
            console.log('market, sport', market, sport)
          const data = await getLeagueStatus(market.name, sport?.id);
          if (data) {
            
            data.forEach((country: any) => {
              country.leagues.sort((a: any, b: any) => a.name.localeCompare(b.name));
            });
            data.sort((a: any, b: any) => a.name.localeCompare(b.name));
            console.log('leaguesStatus', data);
            
            setLeaguesStatus(data);
            setSettingsState((prevState: IStettingsState) => ({
              ...prevState,
              leaguesStatus: data
            }));
          } else {
            console.log('getLeaguesStatus Error');
          }
        } else {
          setLeaguesStatus([]);
          setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            leaguesStatus: []
          }));
          console.log('no data');
        }
      } catch (error) {
        console.error('getLeaguesStatus', error);
      } finally {
        setSettingsState((prevState: IStettingsState) => ({
          ...prevState,
          isLoadingLeaguesStatus: false
        }));
      }
  };

  fetchLeaguesStatus();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sports, markets, sport, market]);

  useEffect(() => {
    if (window.innerWidth < MAX_WIDTH_SMALL_SCREEN) {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        isSmallScreen: true
      }))
    } else {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        isSmallScreen: false
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeftNavOpen])


  useEffect(() => {
    setAppState((prevState: any) => ({
        ...prevState,
        leaguesStatus: leaguesSt
    }))
  }, [leaguesSt])

  
  return (
    <div data-testid={'left-nav-component'} className={`${isLeftNavOpen ? "" : "hidden"}`}>

      {isSmallScreen ?
        (
          <div
            className="absolute top-0 left-0 w-auto h-auto min-w-[100%] min-h-[100%] opacity-[99%] z-50 "
            onClick={closeNav}
          >
            <div className="px-4 py-4 fixed top-0 left-0 flex h-[100%] w-[296px] flex-none flex-col space-y-2 bg-[#111111] text-[14px] transition-all" 
              onClick={e => e.stopPropagation()}>
              <div
                className='cursor-pointer w-[25px] z-50'
                onClick={closeNav}>
                <CloseButton />
              </div>
              <div className='relative h-full'
              >{FiltersContent}</div>
            </div>
          </div>
        )
        :
        (
          <div className="px-4 py-4 fixed top-0 right-0 flex h-full w-[296px] flex-none flex-col space-y-2 bg-[#111111] text-[14px] transition-all sm:relative sm:top-0">
            {FiltersContent}
          </div>
        )}
    </div>
  )
  };

export default SPLeftNav;
