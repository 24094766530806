import { parseJwt } from "api/functions";
import { IAppContext } from "state/context";

export const convertStringArrayToObject = (array: string[]): { id: number, name: string }[] => {
    let id = 1;
    const result: { id: number, name: string }[] = [];
    
    array.forEach((item) => {
      result.push({
        id: id++,
        name: item
      });
    });
    
    return result;
  };


  interface MyObject {
    id: number;
    name: string;
  }

export const  covertObjectArrayToStringArr = (objects: MyObject[]): string[] => {
  return objects.map(obj => obj.name);
}

export const getSVGElementAndSetListener = (id: string, onClick: Function) => {
    const handleClick = () => {
        onClick(id);
    };

    const element = document.getElementById(id);
    if (element !== null) {
        element.addEventListener('click', handleClick);
    } else {
    }

    return () => {
        if (element !== null) {
            element.removeEventListener('click', handleClick);
        }
    }
}

export function setCookie(name: string, value1: any, value2: any, days: number): void {
  let expires = "";
  if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value1 || "") + (value2 ? ("," + value2) : "") + expires + "; path=/";
}

export function setOneValueCookie(name: string, value1: any, days: number): void {
  let expires = "";
  if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value1 || "") + expires + "; path=/";
}

export function getCookie(name: string): string | null {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let cookie of ca) {
      let c = cookie;
      while (c.charAt(0)===' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export function parseStringToObject(inputString: string): { id: string; name: string } {
  const [name, id] = inputString.split(',');
  return { id, name };
}

export const openFeedbackWebsite = (email?: string) => {
  if (email) {
    window.open(`https://forms.clickup.com/4743565/f/4grcd-25952/HM0HV5BRQU9JR30RPV?Email=${email}`, '_blank')
  } else {
  window.open(`https://forms.clickup.com/4743565/f/4grcd-25952/HM0HV5BRQU9JR30RPV?Email=${getUserEmailFromJWT()}`, '_blank')
  }
}

export const getUserEmailFromJWT = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return parseJwt(token).email;
  }
}

export const getUserInitials = () => {
  const email = getUserEmailFromJWT();
  if (email) {
    const initials = email.split('@')[0].split('.').map((word: string) => word[0]).join('');
    return initials.toUpperCase();
  }
}

export const autoExpandOnChange = (e: any, stateKey: "prompt" | "sportsContext" | "output", setAppState: any, addHeight: number = 30) => {
  const value = e.target.value;
  setAppState((prevState: IAppContext) => ({
      ...prevState,
      [stateKey]: value
  }));
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight+addHeight}px`;
};


export function generateUUID()
{
   var chars = '0123456789abcdefghijklmnoprstuwqxyz'.split('');

   var uuid = [], rnd = Math.random, r;
   uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
   uuid[14] = '4';

   for (var i = 0; i < 36; i++)
   {
      if (!uuid[i])
      {
         r = 0 | rnd()*16;
        // eslint-disable-next-line 
         uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
      }
   }

   return uuid.join('');
}

export function formatGPTString(str: string) {
  let formattedString = str.toUpperCase().replace('GPT', 'GPT ');
  if (formattedString === 'GPT 4O') {
    formattedString = 'GPT 4o'; 
  }
  if (formattedString === 'GPT 4O-MINI') {
    formattedString = 'GPT 4o-mini'; 
  }
  return formattedString;
}