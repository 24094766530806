import { ReactComponent as InfoIcon } from 'assets/svg/infoButton.svg';
import Spinner from 'components/common/Modals/modalParts/Spinner';
import { useContext, useEffect, useRef, useState } from 'react';
import { appContext } from 'state/statusPageContext';
import { autoExpandOnChange } from 'utils/general';

const SPStatusList = () => {
    const ctx = useContext(appContext);
    const { setAppState, appState, settingsState } = ctx || {};
    const { isSmallScreen, isLoadingLeaguesStatus } = settingsState;
    const { sportsContext, leaguesStatus } = appState || {};
    const [activeTooltip, setActiveTooltip] = useState<string | null>(null); 
    const sportsContextRef = useRef<HTMLTextAreaElement>(null);

    const onClickSportContextInfo = (id: string) => { 
        setActiveTooltip(id);
        setTimeout(() => {
            setActiveTooltip(null);
        }, 2000)
    }

    const InfoIconWithTooltip = ({ id }: { id: string }) => { 
        return isSmallScreen ? (
          <div onClick={() => onClickSportContextInfo(id)}> 
            <InfoIcon />
          </div>
        ) : (
          <div
            onMouseEnter={() => setActiveTooltip(id)}
            onMouseLeave={() => setActiveTooltip(null)}
          >
            <InfoIcon />
          </div>
        );
      };

    interface ITooltip {
        tooltipInfo: string;
        id: string; 
    }

    const Tooltips = ({ tooltipInfo, id }: ITooltip) => {
        return activeTooltip === id ? ( 
            isSmallScreen ? (
                <div
                    className={`border-2 border-blue-500 relative text-[11px] text-[#EEEEEE] font-semibold flex justify-center items-center bg-[#2C2523] w-[200px] p-1 h-[50px]`}
                    style={{ border: "1px solid #504C4A", zIndex: 999, top: 15, left: 5 }}>
                    {tooltipInfo}
                </div>
            ) :
                (
                    <div
                        className='relative top-8 -right-2 z-9999 text-[11px] text-[#EEEEEE] font-semibold 
                            flex justify-center items-center bg-[#2C2523] w-[200px] p-1 h-[50px]'
                        style={{ border: "1px solid #504C4A", zIndex: 999 }}>
                        {tooltipInfo}
                    </div>
                )
        ) :
            (isSmallScreen ?
                (null)
                : (
                    <div
                        className='relative -top-5 z-15 text-[11px] text-[#EEEEEE] font-semibold w-auto
                        flex justify-center items-center bg-transparent h-[19px]'>
                    </div>
                )
            )
    }

    interface IStatusList {
        title: string;
        tooltipInfo: string;
        isTooltip: boolean;
        id: string;
        mt?: string;
    }

    const HeaderAndInfoButton = ({ title, tooltipInfo, isTooltip, id, mt="16"}: IStatusList) => { 
        return (
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row text-1619 text-[#CCCCCC]  mb-2 ml-2 gap-2 h-[19px] items-center'
                    style={{ marginTop: `${mt}px` }} >
                    {title}
                    {isTooltip && <InfoIconWithTooltip id={id} />} 
                    {isTooltip && <Tooltips tooltipInfo={tooltipInfo} id={id} />}
                </div>
            </div>
        )
    }

    useEffect(() => {
        const handleResizeInput = () => {
            let sportsCtxCurrentRef = sportsContextRef.current;
            if (sportsCtxCurrentRef) {
                let height = sportsCtxCurrentRef.style.height;
                height = 'inherit';
                height = `${sportsCtxCurrentRef.scrollHeight}px`;
            }
        };

        handleResizeInput();
        window.addEventListener('resize', handleResizeInput);

        return () => {
            window.removeEventListener('resize', handleResizeInput);
        };
    }, [sportsContext]);

    interface ILeaguesArray {
        name: string;
        status: string;
    }

    const LeaguesTable = ({ key, leagueGroupName, leaguesArray, availableLeagues }: { key: any, leagueGroupName: string, leaguesArray: ILeaguesArray[], availableLeagues: boolean }) => {
        return (
            <div key={key} className='flex justify-start items-center flex-col text-[#6B6B6B] w-[100%] h-auto border-t-2 border-[#CCCCCC] bg-[#EEEEEE] cursor-info'>
                <div className='w-full font-semibold'>
                    <div className='flex items-center min-h-[35px] pl-2 h-full font-semibold  text-[#6B6B6B]
                                    min-w-[150px] max-w-[150px]
                                    sm:min-w-[300px] sm:max-w-[300px]'>{leagueGroupName}</div>
                </div>
                {leaguesArray.map((league: ILeaguesArray, idx: number) => (
                    (availableLeagues ? league.status === 'League is available on SportsGPT' : league.status !== 'League is available on SportsGPT') && (
                        <div key={idx} className=' w-full border-t-2 border-[#CCCCCC] flex'>
                            <div className='flex items-center min-h-[35px] font-normal border-r-2 border-[#CCCCCC]
                            min-w-[150px] max-w-[150px] pl-[32px]
                            sm:min-w-[300px] sm:max-w-[300px] sm:pl-[32px]'>{league.name}</div>
                            <div className='flex items-center pl-2 min-h-[35px] w-full font-normal'>{league.status}</div>
                        </div>
                    )
                ))}
            </div>
        );
    };

    function renderLeagues(viewType: string, leaguesStatus: any) {
        switch (viewType) {
            case 'AvailibleLeagues':
                return leaguesStatus?.map((leagueGroup: any, index: any) => {
                    const isLeagueAvailable = leagueGroup?.leagues?.some((league: any) => league.status === 'League is available on SportsGPT');
                    return (
                        isLeagueAvailable && <LeaguesTable 
                            key={index} 
                            leagueGroupName={leagueGroup.name} 
                            leaguesArray={leagueGroup.leagues} 
                            availableLeagues={true}

                        />
                    );
                });

            case 'UnavailableLeagues':
                return leaguesStatus?.map((leagueGroup: any, index: any) => {
                    const isLeagueAvailable = leagueGroup?.leagues?.some((league: any) => league.status !== 'League is available on SportsGPT');
                    return (
                        isLeagueAvailable && <LeaguesTable 
                            key={index} 
                            leagueGroupName={leagueGroup.name} 
                            leaguesArray={leagueGroup.leagues} 
                            availableLeagues={false}
                        />
                    );
                });
            default:
                return <div>No view type selected</div>;
        }
    }
    

    const TableHeader = () => {
            return (
                <div className='flex justify-start items-center text-[#6B6B6B] w-[100%] h-auto border-[#CCCCCC] bg-[#EEEEEE] cursor-info' >
                        <div className='flex items-center font-bold min-h-[35px] pl-2 border-r-2 border-[#CCCCCC] 
                                        min-w-[150px] max-w-[150px] 
                                        sm:min-w-[300px] sm:max-w-[300px]'>{"League"}</div>
                        <div className='font-bold ml-2'>{"Status"}</div>
                    </div>
            )
        }

    return (
        <div className='w-[100%] ' >
            {isLoadingLeaguesStatus ? (
                <div className='flex justify-center items-center h-full'>
                    <Spinner />
                </div>
            ) : (
                <div className=''>
                    <HeaderAndInfoButton 
                        title='Leagues Available on SportsGPT' 
                        tooltipInfo='Leagues Available on SportsGPT' 
                        isTooltip={false} 
                        id="available-leagues-tooltip" />
                    {/* <div className={`border-2 rounded-2xl border-[#CCCCCC] overflow-hidden`} > */}
                    <div className={`rounded-2xl overflow-hidden`} >
                        {/* <TableHeader /> */}
                        {renderLeagues('AvailibleLeagues', leaguesStatus)}
                    </div>
                    <HeaderAndInfoButton 
                        mt="30"
                        title='Leagues Currently Not Available on SportsGPT' 
                        tooltipInfo='Leagues Currently Not Available on SportsGPT' 
                        isTooltip={false} 
                        id="unavailable-leagues-tooltip" /> {/* Pass unique id */}
                    <div className={`rounded-2xl overflow-hidden`} >
                        {/* <TableHeader /> */}
                        {renderLeagues('UnavailableLeagues', leaguesStatus)}
                    </div>
                </div>
            )}
        </div>
    )
};

export default SPStatusList;
