import React, { useContext } from 'react';
import { ReactComponent as LeftHideButton } from 'assets/svg/leftHideButton.svg';
import { ReactComponent as RightHideButton } from 'assets/svg/rightHideButton.svg';
import { IStettingsState, appContext } from 'state/statusPageContext';
import { formatGPTString } from 'utils/general';

type NavState = 'isLeftNavOpen' | 'isRightNavOpen';

const SPChatNavHeader = () => {
    const ctx = useContext(appContext);
    const { setSettingsState, settingsState} = ctx || {};
    const { gptModel, isAvailableGPTChange } = settingsState;


    const toggleNavState = (nav: NavState) => {
        setSettingsState((prevState: IStettingsState) => ({
          ...prevState,
          [nav]: !prevState[nav],
        }));
    }

    const renderGPTModel = () => (
      isAvailableGPTChange || gptModel === 'gpt4' || gptModel === 'gpt4o' ? (
          <div id='gpt-model-in-header' className='flex items-center h-full text-1619 text-[#CCCCCC]'>
              {formatGPTString(gptModel)}
          </div>
      ) : null
  );

  const HideButton = ({ direction }: { direction: NavState }) => (
    <div
        data-testid={`${direction}-hide-button`}
        className="cursor-pointer"
        onClick={() => toggleNavState(direction)}
        title={`Hide ${direction === 'isLeftNavOpen' ? 'left' : 'right'} menu`}
    >
        {direction === 'isLeftNavOpen' ? <LeftHideButton /> : <RightHideButton />}
    </div>
);

  

    return (
        <div className="max-h-full">
          <div className="pr-4 pl-4 h-[40px] sticky top-0 z-10 flex justify-between items-center border border-b-neutral-300 text-sm border-none bg-[#333333] text-neutral-200">
            <HideButton direction="isLeftNavOpen" />
            {/* {renderGPTModel()} */}
            {/* <HideButton direction="isRightNavOpen" /> */}
            <div></div>
          </div>
        </div> 
    );
};

export default SPChatNavHeader;

