import { IStettingsState, appContext } from "state/context";
import { useContext } from "react";
import ModalHeader from "components/common/Modals/modalParts/ModalHeader";
import ModalButton from "components/common/Modals/modalParts/ModalButton";
import { MODAL_TYPES } from "enums/modalTypes";

const InfoModal = () => {
    const ctx = useContext(appContext);
    const {isSmallScreen, infoModalText} = ctx?.settingsState || {};
    const { setSettingsState } = ctx || {};

    const onClickOK = () => {
        setSettingsState((prev: IStettingsState) => ({
            ...prev,
            isOpenInfoModal: false,
            infoModalText: ""
        }))
    }

    return (
        <>
        <div className={`flex flex-col min-w-[270px] max-w-md h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4`}
             style={{width: isSmallScreen ? "90%" : "40%" }}>
            <ModalHeader title="Info" modalType={MODAL_TYPES.infoModal}/>
            <div className='flex flex-col ml-4 mt-2 mr-4'>
            <div id="info-modal-text" className="relative flex flex-col items-start text-[#333333] font-bold">
                {infoModalText}
            </div>
            </div>
            <div id="info-modal-button" className='ml-4 mt-5 flex flex-row w-full justify-center'>
                <ModalButton onClick={onClickOK} title="OK" color="#008944" />
            </div>
        </div>
        </>
    )
  }

  export default InfoModal;
