import { IChatHistory } from 'interfaces/interfaces';
import React, { useContext } from 'react';
import { IAppContext, IStettingsState, appContext } from 'state/context';
import { ReactComponent as EditIcon } from 'assets/svg/editButton.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/deleteButton.svg';
import { ReactComponent as ItemIcon } from 'assets/svg/itemButton.svg';
import { FORBIDDEN_CHATS_NAME_LIST } from 'enums/forbiddenChatsNameList';

interface HistoryRowProps {
    chat: IChatHistory;
    index: number;
}

const HistoryRow = ({chat, index}: HistoryRowProps) => {
    const ctx = useContext(appContext);
    const { setAppState, setSettingsState } = ctx || {};


    const onPressDelete = async (chat: IChatHistory) => {
        setSettingsState((prev: any) => ({
            ...prev,
            isOpenDeleteModal: true,
            chatToDelete: chat
        }))
    }

    const onPressEdit = async (chat: IChatHistory) => {
        setSettingsState((prev: any) => ({
            ...prev,
            isOpenRenameModal: true,
            inputValToRename: chat.name,
            inputIdToRename: chat.id,
            promptToRename: chat.user_prompt
        }))
    }

    const onPressChat = () => {
        setAppState((prevState: IAppContext) => ({
            ...prevState,
            prompt: chat.user_prompt,
        }));
        setSettingsState((prev: IStettingsState) => ({
            ...prev,
            isPromptFocus: true
        }))
    }

    const isEditAvailable = () => {
        if (FORBIDDEN_CHATS_NAME_LIST.includes(chat.name)) 
            return false;
        else
            return true;
    }

    const ChatName = () => {
        return (
            <button onClick={onPressChat} 
            className={`flex w-full cursor-pointer items-center gap-3 rounded-lg py-3 px-2 text-sm transition-colors duration-200 ${chat.id === ctx?.appState?.chat?.id ? 'bg-[#343541]/90' : 'bg-transparent'} hover:bg-[#343541]/90`} >
                <ItemIcon />
                <div className="relative flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left text-1619 pr-12">
                    {chat.name}
                </div>
            </button>
        )
    }

    const Buttons = () => {
        return (
            isEditAvailable() ? (
            <div className="absolute right-1 flex text-gray-300">
                <button id={`rename-${chat.name}`} className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100 hover:bg-[#343541]/90 hover:rounded-md" onClick={() => onPressEdit(chat)} title="Rename">
                    <EditIcon />
                </button>
                <button id={`delete-${chat.name}`} className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100 hover:bg-[#343541]/90 hover:rounded-md" onClick={() => onPressDelete(chat)} title="Delete">
                    <DeleteIcon />
                </button>
            </div>)
            : null
        )
    }
    
    return (
        <div data-testid={'history-row'} className="pt-2">
            <div className="flex w-full flex-col gap-1">
                <div className="relative flex items-center bg-transparent text-[#CCCCCC]">
                    <ChatName />
                    <Buttons />
                </div>
            </div>
        </div>
    )
};

export default HistoryRow;


