import React, { useContext } from 'react';
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg';
import { ReactComponent as LogoSVGMobile } from 'assets/svg/logoMobile.svg';
import { getUserInitials, openFeedbackWebsite } from 'utils/general';
import { IStettingsState, appContext } from 'state/context';
import { logFeedbackPressed, logLogout } from 'logs/logFunctions';

interface UserCircleProps {
    userInitials: string;
    onClick: () => void;
}


const ChatMainHeader = () => {
    const userInitials = getUserInitials();
    const ctx = useContext(appContext);
    const { context_id } = ctx?.trackContext || {};
    const { setSettingsState } = ctx || {};
    const { isSmallScreen, isAvailableGPTChange } = ctx?.settingsState || {}


    const onLogout = async () => {
        await logLogout().then(() => {
            localStorage.removeItem('token'); //TODO: go with token name to enums
            setTimeout(() => {
                window.location.reload();
            }, 200);
        });
    }

    const onClickFeedback = () => {
        logFeedbackPressed(context_id)
        openFeedbackWebsite()
    }

    const onUserCircleClick = () => {
        if (isAvailableGPTChange) {
            setSettingsState((prevState: IStettingsState) => ({
                ...prevState,
                isOpenGPTSettingsModal: true
            }))
        }
    }

    const LogoutButton = () => (
        <>
            {isSmallScreen ? (
                <div className={`pr-[14px] mb-[8px] right-[14px] z-49 text-1619 text-[#CCCCCC] cursor-pointer underline`}
                    onClick={onLogout}>Log out
                </div>
            ) : (
                <div className={`absolute right-[16px] z-50 top-[112px] self-end items-end text-1619 text-[#CCCCCC] cursor-pointer underline`}
                    onClick={onLogout}>Log out
                </div>
            )}
        </>
    );

    const FeedbackButton = () => (
        <div data-testid={'welcome'} className='flex flex-row justify-center w-full mt-7 text-[#CCCCCC] text-1619 font-normal'>
            <div className='flex flex-start flex-row gap-1'>
                <span>Provide Your</span>
                <span className='underline cursor-pointer' onClick={onClickFeedback}>Feedback</span>
                <span>on SportsGPT</span>
            </div>
        </div>
    );

    const UserCircle = ({ userInitials, onClick }: UserCircleProps) => (
        <>
            {isSmallScreen ? (
                <div id="user-circle" onClick={onClick} className="bg-[#008944] w-12 h-12 flex items-center justify-center rounded-full cursor-pointer">
                    <span className="text-white font-bold text-1619">{userInitials}</span>
                </div>
            ) : (
                <div id="user-circle" onClick={onUserCircleClick} className="absolute right-[16px] top-[55px] bg-[#008944] w-12 h-12 flex items-center justify-center rounded-full cursor-pointer">
                    <span className="text-white font-bold text-1619 cursor-pointer">{userInitials}</span>
                </div>
            )}
        </>
    );

    return (
        <>
            {
                isSmallScreen ?
                    (
                        <>
                            <div className='w-full flex justify-end pb-4 pr-4'>
                                <LogoutButton />
                                <UserCircle userInitials={userInitials} onClick={onUserCircleClick} />
                            </div>
                            <LogoSVGMobile />
                        </>
                    )
                    : (
                        <>
                            <UserCircle userInitials={userInitials} onClick={onUserCircleClick} />
                            <LogoutButton />
                            <LogoSVG />
                        </>
                    )
            }
            <FeedbackButton />
        </>
    );
};

export default ChatMainHeader;
