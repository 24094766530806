import { ReactComponent as InfoIcon } from 'assets/svg/infoButton.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { appContext } from 'state/context';
import { autoExpandOnChange } from 'utils/general';

const ChatSportsContext = () => {
    const ctx = useContext(appContext);
    const { setAppState, appState, settingsState } = ctx || {};
    const { isSmallScreen } = settingsState;
    const { sportsContext } = appState || {};
    const [isInfoClicked, setIsInfoClicked] = useState(false);
    const sportsContextRef = useRef<HTMLTextAreaElement>(null);

    const onClickSportContextInfo = () => {
        setIsInfoClicked(true);
        setTimeout(() => {
            setIsInfoClicked(false);
        }, 2000)
    }

    const InfoIconWithTooltip = () => {
        return isSmallScreen ? (
          <div onClick={onClickSportContextInfo}>
            <InfoIcon />
          </div>
        ) : (
          <div
            onMouseEnter={() => setIsInfoClicked(true)}
            onMouseLeave={() => setIsInfoClicked(false)}
          >
            <InfoIcon />
          </div>
        );
      };

    const Tooltips = () => {
        return isInfoClicked ? (
            isSmallScreen ? (
                <div
                    className={`border-2 border-blue-500 relative text-[11px] text-[#EEEEEE] font-semibold flex justify-center items-center bg-[#2C2523] w-[200px] p-1 h-[50px]`}
                    style={{ border: "1px solid #504C4A", zIndex: 999, top: 15, left: 5 }}>
                    Add or update information to the sports context in this field
                </div>
            ) :
                (
                    <div
                        className='relative top-8 -right-2 z-9999 text-[11px] text-[#EEEEEE] font-semibold 
                            flex justify-center items-center bg-[#2C2523] w-[200px] p-1 h-[50px]'
                        style={{ border: "1px solid #504C4A", zIndex: 999 }}>
                        Add or update information to the sports context in this field2
                    </div>
                )
        ) :
            (isSmallScreen ?
                (null)
                : (
                    <div
                        className='relative -top-5 z-15 text-[11px] text-[#EEEEEE] font-semibold w-auto
                        flex justify-center items-center bg-transparent h-[19px]'>
                    </div>
                )
            )
    }


    const HeaderAndInfoButton = () => {
        return (
            <div className='flex flex-row mt-4 text-1619 text-[#CCCCCC] w-[100%] mb-2 ml-8 gap-2 h-[19px]' >
                Sports Context
                <InfoIconWithTooltip />
                <Tooltips />
            </div>
        )
    }

    useEffect(() => {
        const handleResizeInput = () => {
            let sportsCtxCurrentRef = sportsContextRef.current;
            if (sportsCtxCurrentRef) {
                let height = sportsCtxCurrentRef.style.height;
                height = 'inherit';
                height = `${sportsCtxCurrentRef.scrollHeight}px`;
            }
        };

        handleResizeInput();
        window.addEventListener('resize', handleResizeInput);

        return () => {
            window.removeEventListener('resize', handleResizeInput);
        };
    }, [sportsContext]);

    return (
        <>
            <HeaderAndInfoButton />
            <div data-testid={'sports-context'} className=' relative w-[100%] min-h-[110px] h-auto border-2 border-[#CCCCCC] rounded-2xl px-4 pt-4 bg-[#EEEEEE]'>
                <textarea className='border-none w-[90%] resize-none text-[#6B6B6B] bg-[#EEEEEE] focus:outline-none text-1619 custom-scrollbar-transparent'
                    ref={sportsContextRef}
                    onChange={(e) => autoExpandOnChange(e, 'sportsContext', setAppState, 10)}
                    style={{ height: 'auto', minHeight: '128px' }}
                    value={sportsContext}
                />
            </div>
        </>
    )
};

export default ChatSportsContext;
