export const MODAL_TYPES = {
    saveHistory: "isOpenSaveModal" as const,
    renameHistoryItem: "isOpenRenameModal" as const,
    deleteItem: "isOpenDeleteModal" as const,
    infoModal: "isOpenInfoModal" as const,
    varsModal: "isOpenVarsModal" as const,
    gptSettings: "isOpenGPTSettingsModal" as const,
    FeedbackNudging: "isFeedbackNudgingOpen" as const
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MODAL_TYPES = typeof MODAL_TYPES[keyof typeof MODAL_TYPES];