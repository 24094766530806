import Spinner from "components/common/Modals/modalParts/Spinner";
import { MouseEventHandler } from "react";

interface ModalButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    title?: string;
    color?: string;
    textColor?: string;
    w?: number;
    h?: number;
    isLoading?: boolean;
    spinnerColor?: string;
    useOn?: string;
    roundSize?: string;
}

const ModalButton = ({ onClick = () => console.log('button'), title = "Button", color = "#008944", textColor = "#EEEEEE", w = 130, h = 40, isLoading = false, spinnerColor, useOn, roundSize = 'rounded-xl' }: ModalButtonProps) => {
    const handleKeyDown = (e: any) => {
        if (e.key === useOn) {
            onClick(e);
        }
    };

    return (
        <button
            className={`border-2 border-[#CCCCCC] text-[${textColor}] font-medium text-1619 ${roundSize}`}
            onClick={onClick}
            style={{ marginRight: "10px", backgroundColor: color, width: w, height: h }}
            onKeyDown={handleKeyDown}
        >{isLoading ? (
            spinnerColor ? <Spinner borderColor={spinnerColor}></Spinner> : <Spinner></Spinner>
        ) : (
            title
        )}</button>
    )
}

export default ModalButton;
