import React, { useContext } from 'react';
import { IStettingsState, appContext } from 'state/context';
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg';
import ModalButton from 'components/common/Modals/modalParts/ModalButton';
import { commonContext } from 'state/commonContext';


const ErrorScreen = () => { 
    const ctx = useContext(appContext);
    const commonCtx = useContext(commonContext)
    const { isAvailableGPTChange, isRegistrationDisabled, isGPT4switchActive, isGPT3_5switchActive, gptModel,
         } = ctx?.settingsState || {};

    const { isConnectionLost, isShownErrorPage, isOverLimit, isOverLimitGPT4 } = commonCtx?.commonCfgState || {};
    
    const { setSettingsState } = ctx || {};
    const { isSmallScreen } = ctx?.settingsState || {};

    const switchModelToGPT4 = () => () => {
        localStorage.setItem('gpt_model', 'gpt4');
        setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            gptModel: 'gpt4',
            isGPT4switchActive: true,
            isLeftNavOpen: isSmallScreen ? false : true,
            isRightNavOpen: isSmallScreen ? false : true,
            isAvailableGPTChange: false,
        }));
    };

    const switchModelToGPT3_5 = () => () => {
        localStorage.setItem('gpt_model', 'gpt3.5');
        setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            gptModel: 'gpt3.5',
            isGPT3_5switchActive: true,
            isLeftNavOpen: isSmallScreen ? false : true,
            isRightNavOpen: isSmallScreen ? false : true,
            isAvailableGPTChange: false,
        }));
    };

    const errorsToRender = [
        {
            isOpen: isConnectionLost,
            title: '',
            message: [
                'Could not connect to a server, please check your internet connection.'
            ],
            buttons: []
        },
        {
            isOpen: isShownErrorPage, 
            title: 'Oops, Something Went Wrong!', 
            message: [
                'We\'re experiencing some technical difficulties  with our chat service at the moment.',
                'Our team is working hard to get things back to normal. ',
                'Please try again shortly.', 
                'We appreciate your  patience!'
            ],
            buttons: []
        },
        {
            isOpen: isOverLimit && isAvailableGPTChange && !isOverLimitGPT4 && !isGPT4switchActive && gptModel !== 'gpt4', 
            title: 'Welcome Back', 
            message: [
                'We are currently experiencing a large number of requests',
                'and have to pause additional usage of the system.',
                'Please check back tomorrow.',
                '<br />',
                'Or continue usage by switching to GPT 4.',
                '<br />'
            ],
            buttons: [{title: "Switch to GPT 4", onClick: switchModelToGPT4()}]
        },
        {
            isOpen: isOverLimitGPT4 && isAvailableGPTChange && !isOverLimit && !isGPT3_5switchActive && gptModel !== 'gpt3.5', 
            title: 'Welcome Back', 
            message: [
                'We are currently experiencing a large number of requests',
                'and have to pause additional usage of the system.',
                'Please check back tomorrow.',
                '<br />',
                'Or continue usage by switching to GPT 3.5.',
                '<br />'
            ],
            buttons: [{title: "Switch to GPT 3.5", onClick: switchModelToGPT3_5()}]
        },
        {
            isOpen: (isOverLimit && !isAvailableGPTChange && !isGPT4switchActive) || (isOverLimit && isOverLimitGPT4), 
            title: 'Welcome Back', 
            message: [
                'We are currently experiencing a large number of requests',
                'and have to pause additional usage of the system.',
                'Please check back tomorrow.'
            ],
            buttons: []
        },
        {
            isOpen: isRegistrationDisabled, 
            title: 'Sign-Up Temporarily Paused Due to High Demand', 
            message: [
                'Thank you for your interest in joining us!',
                'Due to an exceptionally high volume of sign-ups today,we have reached over 90% of our daily capacity.', 
                'To ensure the best experience for everyone, we have temporarily paused new registrations.',
                'Please check back tomorrow.',
                'We appreciate your understanding and look forward to welcoming you soon!'
            ],
            buttons: []
        }
    ];

    const renderError = () => {
        for (let i = 0; i < errorsToRender.length; i++) {
            const { isOpen, title, message, buttons} = errorsToRender[i];
            if (isOpen) {
                return (
                    <div key={i} style={{ zIndex: 52 }} className="h-full w-full mt-[54px] bg-[#111111] flex flex-col items-center justify-between text-1619 text-[#CCCCCC] font-normal text-center">
                        <LogoSVG />
                        <div className='mt-[26px] mb-[2rem]'>{title}</div>
                        <div style={{
                            border: '2px solid #CCCCCC',
                            borderRadius: '16px',
                            padding: '20px 50px',
                            backgroundColor: '#4A4A4A',
                            }}>
                            {message.map((msg, index) => (
                                msg.includes('<br />') ? <br /> : <div key={index}>{msg}</div>
                            ))}
                        </div>
                        <div className='mt-4 cursor-pointer'>
                         {buttons.length > 0 && buttons?.map((button: any, index) => (
                            <ModalButton 
                                key={index}
                                w={336} 
                                h={48} 
                                title={button?.title} 
                                onClick={button?.onClick} 
                            />
                        ))}
                        </div>
                    </div>
                );
            }
        }
        return null;
    };

    return (
        <div>
            {renderError()}
        </div>
    );
};

export default ErrorScreen;
