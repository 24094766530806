import { IStettingsState, appContext } from "state/context";
import { useContext, useState } from "react";
import ModalHeader from "components/common/Modals/modalParts/ModalHeader";
import ModalButton from "components/common/Modals/modalParts/ModalButton";
import { deleteChat, getUserChats } from "api/functions";
import { MODAL_TYPES } from "enums/modalTypes";
import { ILogDeletedPrompt } from "interfaces/interfaces";
import { logDeletedPrompt } from "logs/logFunctions";

const DeleteModal = () => {
    const ctx = useContext(appContext);
    const { context_id } = ctx?.trackContext || {};
    const { chatToDelete, isSmallScreen } = ctx?.settingsState || {};
    const { setAppState, setSettingsState } = ctx || {};
    const [message, setMessage] = useState("")
    const [isSaving, setIsSaving] = useState(false)

    const onClickYes = async () => {
        setIsSaving(true)
        if (chatToDelete?.id) {
            const delChat = await deleteChat(chatToDelete.id)
            if (delChat) {
                if (delChat === 400 ) {
                    setMessage("System chat cannot be deleted")
                    setIsSaving(false)
                    return;
                } else {
                    logDeletedPrompt(context_id, chatToDelete.name)
                }
                const chats = await getUserChats()
                if (chats) {
                    setAppState((prevState: IStettingsState) => ({
                        ...prevState,
                        chatsHistory: chats
                    }));
                    setIsSaving(false)
                    setSettingsState((prev: IStettingsState) => ({
                        ...prev,
                        isOpenDeleteModal: false
                    }))
                }
                onClickNo()
            } else {
                setIsSaving(false)
                setMessage("Deleting chat error, try again")
            }
        } else {
            setIsSaving(false)
                setMessage("Please back and enter a prompt")
        }
    }

    const onClickNo = () => {
        setSettingsState((prev: IStettingsState) => ({
            ...prev,
            chatIdToDelete: null,
            isOpenDeleteModal: false
        }))
    }

    return (
        <>
        <div className={`flex flex-col min-w-[270px] max-w-md h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4`}
             style={{width: isSmallScreen ? "90%" : "40%" }}>
            <ModalHeader title="Delete Chat" modalType={MODAL_TYPES.deleteItem}/>
            <div className='flex flex-col ml-4 mt-2 mr-4'>
            <div className="relative flex flex-col items-start text-[#333333] font-bold"> 
                Delete history prompt {chatToDelete.name} ?
                {<div className="ml-1 mt-1 self-start text-[#A12300] text-1214">{message}</div>}
            </div>
            </div>
            <div className='mt-5 flex flex-row w-full justify-end'>
                <ModalButton onClick={onClickNo} title="Cancel" color="#A4A4A4" />
                <ModalButton onClick={onClickYes} title="Delete" color="#A12300" spinnerColor="#A12300" isLoading={isSaving} />
            </div>
        </div>
        </>
    )
  }

  export default DeleteModal;
