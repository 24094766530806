import { appContext } from "state/context";
import { useContext, useEffect, useRef, useState } from "react";
import ModalHeader from "components/common/Modals/modalParts/ModalHeader";
import ModalButton from "components/common/Modals/modalParts/ModalButton";
import { getUserChats, patchRenameChat } from "api/functions";
import { MODAL_TYPES } from "enums/modalTypes";

const RenameChatModal = () => {
    const ctx = useContext(appContext);
    const { promptToRename, isSmallScreen, inputIdToRename, inputValToRename} = ctx?.settingsState || {};
    const { setAppState, setSettingsState } = ctx || {};
    const { chatsHistory } = ctx?.appState || {};

    const [inputVal, setInputVal] = useState("")
    const inputRef = useRef(null);
    const [message, setMessage] = useState("")
    const [isSaving, setIsSaving] = useState(false)

    const onChangeVal = (e:any) => {
        setInputVal(e.target.value)
    }
    const onClickRename = async () => {
        const inputTrimmed = inputVal.trimEnd()
        setIsSaving(true)
        if (promptToRename && inputTrimmed && inputIdToRename) {
            const chats = chatsHistory;
            const chatNameExists = chats?.some(chat => chat.name === inputTrimmed && chat.id !== inputIdToRename);
            if (chatNameExists) {
                setMessage("This name is already used in the chat history");
                setIsSaving(false)
                return;
            }
            const updateChat = await patchRenameChat(promptToRename, inputTrimmed, inputIdToRename)
            if (updateChat) {
                if (updateChat === 400) {
                    setMessage("System chat cannot be renamed")
                    setIsSaving(false)
                    return;
                }
                const chats = await getUserChats()
                if (chats) {
                    setAppState((prevState: any) => ({
                        ...prevState,
                        chatsHistory: chats
                    }));
                    setIsSaving(false)
                    setSettingsState((prev: any) => ({
                        ...prev,
                        isOpenRenameModal: false
                    }))
                } 
            } else {
                setIsSaving(false)
                setMessage("Saving chat error, try again")
            }
        } else {
            setIsSaving(false)
            if (!inputVal) {
                setMessage("Please enter a name")
            }
            if (!promptToRename) {
                setMessage("Please back and enter a prompt")
            }
        }
    }

    const onClickCancel = () => {
        setInputVal("")
        setSettingsState((prev: any) => ({
            ...prev,
            isOpenRenameModal: false
          }))
    }

    useEffect(() => {
            setInputVal(inputValToRename) 
    }, [inputValToRename])

    return (
        <>
        <div className={`flex flex-col min-w-[270px] max-w-md h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4`}
            style={{width: isSmallScreen ? "90%" : "40%" }}>
            <ModalHeader title="Rename Chat" modalType={MODAL_TYPES.renameHistoryItem}/>
            <div className='flex flex-col ml-4 mt-2 mr-4'>
            <div className='min-w-[160px] text-1619 text-[#333333] font-bold mb-2'>New name:</div>
            <div className="relative flex flex-col items-center ">
                <input 
                id="rename-input"
                ref={inputRef}
                className={`placeholder:font-normal w-full min-h-[48px] flex-1 rounded-2xl text-[16px] p-3 pr-10 leading-3 text-[#6B6B6B] hover:opacity-90 bg-[#EEEEEE] focus:outline-none
                focus:border-[#008944] border-2 border-[#CCCCCC]}`}
                type="text"
                autoFocus
                value={inputVal}
                placeholder="Enter chat name"
                onChange={(e) => onChangeVal(e)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onClickRename()
                    }
                  }}
                />
                {<div className="ml-1 mt-1 self-start text-[#A12300] text-1214">{message}</div>}
            </div>
            </div>
            <div className='mt-5 flex flex-row w-full justify-end'>
                <ModalButton onClick={onClickCancel} title="Cancel" color="#A4A4A4"/>
                <ModalButton onClick={onClickRename} title="Rename" isLoading={isSaving}/>
            </div>
        </div>
        </>
    )
  }

  export default RenameChatModal;
