import React, { useEffect, useRef, useState } from 'react';
import SPRightNav from 'components/StatusPage/RightNav/SPRightNav';
import Spinner from 'components/common/Modals/modalParts/Spinner';
import { getIsOverLimit, getProfile, getUserChats } from 'api/functions';
import { IAppContext, initialMainState, IStettingsState, initialSettingsState, appContext, ITrackContext, initialTrackContext } from 'state/statusPageContext';
import { initialCommonCfgState } from 'state/commonContext';
import RenderModals from 'components/common/Modals/RenderModals';
import ErrorScreen from 'components/common/ErrorScreen/ErrorScreen';
import { MAX_WIDTH_SMALL_SCREEN } from 'enums/others';
import { IChatHistory } from 'interfaces/interfaces';
import SPLeftNav from 'components/StatusPage/SPLeftNav/SPLeftNav';
import SPMiddle from 'components/StatusPage/Middle/SPMiddle';
import { ICfgState, commonContext } from 'state/commonContext';

function StatusMainPage() {
  const [appState, setAppState] = useState<IAppContext>(initialMainState)
  const [settingsState, setSettingsState] = useState<IStettingsState>(initialSettingsState)
  const [commonCfgState, setCommonCfgState] = useState<ICfgState>(initialCommonCfgState)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [trackContext, setTrackContext] = useState<ITrackContext>(initialTrackContext)
  const mainScreenRef = useRef<HTMLDivElement>(null);
  const settingsStateRef = useRef(settingsState);
  const overLimitFetchningCounter = useRef(0);

  const isErrorMsg = () => {
    if (commonCfgState.isConnectionLost) {
      return true;
    }
    if (settingsState.isRegistrationDisabled || commonCfgState.isShownErrorPage) {
      return true;
    }
    // else if (commonCfgState.isOverLimit) {
    //   if (settingsState.isAvailableGPTChange && !commonCfgState.isOverLimitGPT4 && !settingsState.isGPT4switchActive && settingsState.gptModel !== 'gpt4') {
    //     return true;
    //   }
    //   else if (!settingsState.isAvailableGPTChange && !settingsState.isGPT4switchActive) {
    //     return true;
    //   }
    //   else if (commonCfgState.isOverLimitGPT4) {
    //     return true;
    //   }
    // }
    // else if (commonCfgState.isOverLimitGPT4) {
    //   if (settingsState.isAvailableGPTChange && !commonCfgState.isOverLimit && !settingsState.isGPT3_5switchActive && settingsState.gptModel !== 'gpt3.5')
    //     return true;
    // }
    else {
      return false;
    }
  }

  useEffect(() => {
    const fetchUserChats = async () => {
      try {
        const res = await getUserChats();
        if (res) {
          setAppState((prevState: IAppContext) => ({
            ...prevState,
            chatsHistory: res as IChatHistory[] || []
          }));
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
        setIsLoaded(true);
      } catch (err) {
        setIsLoaded(true);
        setIsLoggedIn(false);
      }
    };

    const fetchProfile = async () => {
      try {
        const allowedModels = await getProfile();
        if (allowedModels) {
          setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            gptModels: allowedModels.map(model => String(model))
          }));
          const localStorageGPTModel = localStorage.getItem('gpt_model');
          if (localStorageGPTModel && allowedModels.includes(localStorageGPTModel)) {
            setSettingsState((prevState: IStettingsState) => ({
              ...prevState,
              gptModel: localStorageGPTModel
            }));
          } else {
            setSettingsState((prevState: IStettingsState) => ({
              ...prevState,
              gptModel: allowedModels.includes('gpt4o') ? 'gpt4o' : 'gpt3.5'
            }));
            localStorage.setItem('gpt_model', allowedModels.includes('gpt4o') ? 'gpt4o' : 'gpt3.5');
          }
        }
        if (allowedModels.length > 1) {
          setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            isAvailableGPTChange: true,
          }))
        } else {
          setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            isAvailableGPTChange: false,
          }))
        }
      } catch (err: any) {
        throw new Error(err);
      }
    }

    fetchProfile();
    fetchUserChats();
  }, [commonCfgState.isShownErrorPage, commonCfgState.isOverLimit, commonCfgState.isOverLimitGPT4, settingsState.isRegistrationDisabled]);

  useEffect(() => {
    if (window.innerWidth < MAX_WIDTH_SMALL_SCREEN) {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        isLeftNavOpen: false,
        isRightNavOpen: false,
        isSmallScreen: true
      }))
    } else {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        isLeftNavOpen: true,
        isRightNavOpen: true,
        isSmallScreen: false
      }))
    }
  }, [])

  // useEffect(() => {
  //   const fetchIsOverLimitOrError = async () => {
  //     try {
  //       const res = await getIsOverLimit();
  //       if (res === 'Failed to fetch') {
  //         setCommonCfgState((prevState: ICfgState) => ({
  //           ...prevState,
  //           isConnectionLost: true,
  //         }));
  //       } else {
  //         setCommonCfgState((prevState: ICfgState) => ({
  //           ...prevState,
  //           isConnectionLost: false,
  //         }));
  //       }
  //       if (res && typeof res === 'boolean' && res === true) {
  //         setCommonCfgState((prevState: ICfgState) => ({
  //           ...prevState,
  //           isShownErrorPage: true,
  //         }));
  //       }
  //       if (res === false) {
  //         setCommonCfgState((prev: ICfgState) => ({
  //           ...prev,
  //           isOverLimit: false,
  //           isShownErrorPage: false,
  //         }))
  //       } else if (res && typeof res === 'object') {
  //         if (res.limit_exceeded && !res.limit_exceeded_gpt4 && settingsStateRef.current.isAvailableGPTChange && !settingsStateRef.current.isGPT4switchActive && settingsStateRef.current.gptModel === 'gpt4') {
  //           setSettingsState((prev: IStettingsState) => ({
  //             ...prev,
  //             isGPT4switchActive: true,
  //           }))
  //         }
  //         setCommonCfgState((prev: ICfgState) => ({
  //           ...prev,
  //           isOverLimit: res.limit_exceeded,
  //           isShownErrorPage: res.show_error_page,
  //           isOverLimitGPT4: res.limit_exceeded_gpt4,
  //         }))
  //         if (overLimitFetchningCounter.current < 1 || res.feedback_nudge) {
  //           setCommonCfgState((prev: ICfgState) => ({
  //             ...prev,
  //             isFeedbackNudgingOpen: res.feedback_nudge,
  //           }))
  //           overLimitFetchningCounter.current += 1;
  //         }
  //       }
  //     } catch (err: any) {
  //       throw new Error(err);
  //     }
  //   };

  //   fetchIsOverLimitOrError();
  //   const overLimitInterval = setInterval(() => {
  //     fetchIsOverLimitOrError();
  //   }
  //     , 15000)

  //   return () => {
  //     clearInterval(overLimitInterval)
  //   }
  // }, [])

  //TODO: check bahaviour of this useEffect if is still nedded after ErrorScreen refactoring
  useEffect(() => {
    if (commonCfgState.isOverLimit || commonCfgState.isShownErrorPage) {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        // isLeftNavOpen: false, // should we set this value instead useEffect in ErroScreen? 
        // isRightNavOpen: false, // should we set this value instead useEffect in ErroScreen? 
      }))
    } else {
      if (window.innerWidth > MAX_WIDTH_SMALL_SCREEN) {
        setSettingsState((prev: IStettingsState) => ({
          ...prev,
          isLeftNavOpen: true,
          isRightNavOpen: true,
        }))
      }
    }
  }, [commonCfgState.isOverLimit, commonCfgState.isShownErrorPage])


  useEffect(() => {
    const isNavOpenOnSmallScreen = settingsState.isSmallScreen && (settingsState.isLeftNavOpen || settingsState.isRightNavOpen);

    if (isNavOpenOnSmallScreen) {
      mainScreenRef.current?.classList.add('overflow-y-hidden');
      document.documentElement.style.overflow = 'hidden';
    } else {
      mainScreenRef.current?.classList.remove('overflow-y-hidden');
      document.documentElement.style.removeProperty('overflow');
    }
  }, [settingsState.isLeftNavOpen, settingsState.isRightNavOpen, settingsState.isSmallScreen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth) {
        if (window.innerWidth < MAX_WIDTH_SMALL_SCREEN) {
          setSettingsState((prev: IStettingsState) => ({
            ...prev,
            isLeftNavOpen: false,
            isRightNavOpen: false,
            isSmallScreen: true
          }))
        } else {
          setSettingsState((prev: IStettingsState) => ({
            ...prev,
            isLeftNavOpen: true,
            isRightNavOpen: true,
            isSmallScreen: false
          }))
        }
      }
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const loadDefaultMarket = () => {
      const defaultMarket: any = localStorage.getItem('default_market');
      if (defaultMarket) {
        setAppState((prevState: any) => ({
          ...prevState,
          defaultFilters: {
            ...prevState.defaultFilters,
            market: JSON.parse(defaultMarket)
          }
        }));
      }
    }
    loadDefaultMarket();
  }, [])

  return (
    <appContext.Provider value={{ appState, setAppState, settingsState, setSettingsState, trackContext, setTrackContext }}>
      <commonContext.Provider value={{ commonCfgState, setCommonCfgState }}>
        {isErrorMsg() ? (
          <ErrorScreen />
        ) :
          (
            <>
              {isLoaded ? (
                <div>
                  <RenderModals />
                  {settingsState.isSmallScreen && (settingsState.isLeftNavOpen || settingsState.isRightNavOpen) ?
                    <div
                      className='fixed top-0 left-0 h-full w-full z-50 bg-[#D9D9D9BF] flex justify-center items-center' />
                    : null}
                  <div ref={mainScreenRef} className={`flex h-screen w-screen flex-row text-sm text-white `}>
                    {isLoggedIn ? <SPLeftNav /> : null}
                    <SPMiddle isLoggedIn={isLoggedIn} />
                    {isLoggedIn ? <SPRightNav /> : null}
                  </div>
                </div>
              ) : <div className="flex justify-center items-center h-screen w-screen flex-row text-sm text-white bg-[#111111]">
                <Spinner />
              </div>}
            </>)}
      </commonContext.Provider>
    </appContext.Provider>
  );
}

export default StatusMainPage;
