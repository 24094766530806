import { sendEmail } from 'api/functions';
import React, { useContext, useRef, useState } from 'react';
import { ReactComponent as LogoLogin } from 'assets/svg/logoLogin.svg';
import Spinner from 'components/common/Modals/modalParts/Spinner';
import { IStettingsState, appContext } from 'state/context';
import { ILogEmailSent } from 'interfaces/interfaces';
import { openFeedbackWebsite } from 'utils/general';
import { logEmailSent } from 'logs/logFunctions';

const Login = () => {
  const [email, setEmail] = useState("");
  const [messageLine1, setMessageLine1] = useState("");
  const [messageLine2, setMessageLine2] = useState("");
  // eslint-disable-next-line 
  const [isErrorMsg, setIsErrorMsg] = useState(true);
  const continueRef = useRef<null | HTMLButtonElement>(null)
  const [continueIsClicked, setContinueIsClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessMsg, setIsSuccessMsg] = useState(false)
  const ctx = useContext(appContext);

  
  const onContinue = async () => {
    setIsLoading(true);
    if (email === "") {
      setIsErrorMsg(true);
      setIsSuccessMsg(false);
      setMessageLine1("Please enter a valid email address");
      setMessageLine2("");
      setIsLoading(false);
      return;
    } else {
      if (email.includes("@kindredgroup.com")) {
        try {
          const res = await sendEmail(email);
          if (res.success === false) {
            setMessageLine1(res.message);
            setIsErrorMsg(true);
            setIsLoading(false);
            setIsSuccessMsg(false);
            if (res?.statusCode === 432) {
              ctx?.setSettingsState((prevState: IStettingsState) => ({
                ...prevState,
                isRegistrationDisabled: true
              }));
            }
          } else {
            setMessageLine1(`Email with access link sent! The email might get caught by your spam filter so...`);
            // setMessageLine2(`Please remember to check your spam folder.`);
            setIsErrorMsg(false);
            // setEmail("");
            setIsSuccessMsg(true);
            setIsLoading(false);
            logEmailSent();
          }
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      } else {
        setIsErrorMsg(true);
        setIsSuccessMsg(false);
        setMessageLine1('Valid email should contain @kindredgroup.com');
        setMessageLine2("");
        setIsLoading(false);
      }
    }
  };
  
  const handleClick = () => {
    setContinueIsClicked(true)
    onContinue()
    setTimeout(() => {
      setContinueIsClicked(false)
    }, 100);
  };

  const onBackToFrontPage = () => {
    setIsSuccessMsg(false)
    setMessageLine1("")
    setMessageLine2("")
    setEmail("")
  }

  return (
    <div data-testid={'login-component'} className="flex flex-1">
          <div className="relative flex-1 overflow-hidden bg-[#111111]"> 
            <div className='w-full h-full flex flex-col items-center mt-[54px]'>
              <LogoLogin />
              <div className='mt-[27px] mb-[78px] text-1619 text-[#CCCCCC]'>Welcome Back</div>
              {isSuccessMsg ? null : 
              (<>
                <div className={`mt-[131px] absolute flex text-1619 font-normal text-[#CCCCCC]`}>
                  Sign in with company email:
                </div>
                <div className="w-[336px] h-[48px] relative flex flex-row">
                  <input 
                    data-testid={'login-email-input'}
                    type="email" 
                    placeholder="" 
                    className="py-4 pl-3 w-full h-full text-[#6B6B6B] font-normal rounded-2xl text-1619 focus:outline-none placeholder:text-1619 placeholder:absolute placeholder:left-3 "
                    style={{border: "2px solid #CCCCCC"}}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleClick()
                      }
                    }}
                    autoFocus
                    />
                </div>
              </>)}
              {messageLine1 ? (
                  <div data-testid={'login-message'} className={`text-[#CCCCCC] mb-4 mt-4 font-normal flex flex-col text-center`}>
                    <div className='self-center'>{messageLine1}</div>
                    <div className='self-center'>{messageLine2}</div>
                    </div>
                ) 
              : (
                <div className='mb-6'></div>
              )}
              {isSuccessMsg ? (
                <div className='flex items-center justify-center text-center flex-col ml-2 mr-2 text-[#CCCCCC]'>
                  <div className='bg-[#4A4A4A] text-[#CCCCCC] pl-4 pr-4 pt-2 pb-2 rounded-2xl mb-4 border-2 border-[#CCCCCC] w-[320px] '>PLEASE CHECK YOUR SPAM FOLDER</div>
                  <div className='underline cursor-pointer text-[#CCCCCC] mb-4' onClick={onBackToFrontPage}>Back to front page</div>
                  <div>If you need assistance to log in, please notify us via the <span className='underline cursor-pointer' onClick={() => openFeedbackWebsite(email)}>feedback link</span> </div>
                  <div>or reach out to <a className="underline cursor-pointer" href="mailto:nikolay.tymtsiv@kindredgroup.com" target="_top" >nikolay.tymtsiv@kindredgroup.com</a> or</div>
                  <div><a className="underline cursor-pointer" href="mailto:pal.osterud@kindredgroup.com">pal.osterud@kindredgroup.com</a> directly on email or Teams.</div>
                </div>
              )
              : 
              (<button 
                data-testid={'login-continue-button'}
                ref={continueRef}
                title={"Continue"}
                className={`${continueIsClicked ? "bg-[#008944]/80" : "bg-[#008944]"} w-[336px] h-[48px] rounded-2xl text-white text-1619 active:bg-[#008944]/80 hover:bg-[#008944]/90`}
                style={{border: "2px solid #CCCCCC"}}
                onClick={onContinue}
              >
              {isLoading ? <Spinner /> : "Continue"}
              </button>)}
            </div>
          </div>
    </div>
  )
  };

export default Login;
