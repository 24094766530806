import { Market, Sport, Tournament, MyEvent, Media, IChat, IChatHistory, IBBAndVS, DefaulFilters } from "interfaces/interfaces";
import { createContext } from "react";

export interface ICfgState {
    isConnectionLost: boolean;
    isShownErrorPage: boolean;
    isOverLimit: boolean;
    isOverLimitGPT4: boolean;
    isFeedbackNudgingOpen: boolean;

}

export interface ICfgContextProps {
    commonCfgState: any;
    setCommonCfgState: any;
}

export const initialCommonCfgState: ICfgState = {
    isConnectionLost: false,
    isShownErrorPage: false,
    isOverLimit: false,
    isOverLimitGPT4: false,
    isFeedbackNudgingOpen: false,
}

export const commonContext = createContext<ICfgContextProps | null>(null);