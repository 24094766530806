import React from 'react';
import SPLogin from 'components/StatusPage/Middle/Login/SPLogin';
import SPChat from './Chat/SPChat';

interface MiddleProps {
  isLoggedIn: boolean;
}

const SPMiddle = ({isLoggedIn}: MiddleProps) => {
  return (
    <>
      {isLoggedIn ? <SPChat /> : <SPLogin/>}
    </>
  )
  };

export default SPMiddle;
