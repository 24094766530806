import { postLog } from "api/functions"
import { ILogFeedbackNudgingClosed, ILogFeedbackNudgingAccepted, ILogFeedbackPressed, ILogDeletedPrompt, ILogSavePrompt, ILogLogout, ILogCopyPressed, ILogFormSubmit, ILogEmailSent } from "interfaces/interfaces"

export const logFeedbackPressed = (context_id: string | undefined) => {
    const log: ILogFeedbackPressed =
    {
        timestamp: `${new Date().toISOString()}`,
        action_details: "feedback_pressed",
        context_id: `${context_id}`,
    }
    postLog(log)
}

export const logFeedbackNudgingAccepted = () => {
    const log: ILogFeedbackNudgingAccepted =
    {
        timestamp: `${new Date().toISOString()}`,
        action_details: "feedback_nudging_pressed",
    }
    postLog(log)
}

export const logFeedbackNudgingClosed = () => {
    const log: ILogFeedbackNudgingClosed =
    {
        timestamp: `${new Date().toISOString()}`,
        action_details: "feedback_nudging_closed",
    }
    postLog(log)
}

export const logDeletedPrompt = (context_id: string | undefined, chatToDeleteName: string) => {
    const log: ILogDeletedPrompt =
    {
      timestamp: `${new Date().toISOString()}`,
      action_details: "prompt_deleted",
      context_id: `${context_id}`,
      name: `${chatToDeleteName}`
    }
    postLog(log)
}


export const logSavePrompt = (context_id: string | undefined, inputVal: string, prompt: string) => {
    const log: ILogSavePrompt =
    {
      timestamp: `${new Date().toISOString()}`,
      action_details: "prompt_saved",
      context_id: `${context_id}`,
      name: `${inputVal.trimEnd()}`,
      value: `${prompt}`
    }
    postLog(log)
}

export const logDropdownChange = (context_id: string | undefined, marketName: string | null | undefined, sportName: string | null | undefined, 
    tournamentName: string | null | undefined, myEventName: string | null | undefined, myEventId: number | null | undefined) => {
    const log ={
      context_id: `${context_id}`,
      market: `${marketName}`,
      sports: `${sportName}`,
      league: `${tournamentName}`,
      match: `${myEventName}`,
      match_id: `${myEventId}`,
      context_set_timestamp: `${new Date().toISOString()}`
      }
      postLog(log)
  }

export const logLogout = async () => {
    const log: ILogLogout =
    {
        timestamp: `${new Date().toISOString()}`,
        action_details: "user_logged_out", //TODO: go with action_details to enums
    }
    await postLog(log)
}

export const logCopyPressed = (context_id: string | undefined, outPutValue: string | undefined) => {
    const log: ILogCopyPressed=
    {
      timestamp: `${new Date().toISOString()}`,
      action_details: "copy_pressed",
      context_id: `${context_id}`,
      output: `${outPutValue ?? ""}`,
    }
    postLog(log)
}

export const logFormSubmit = (sportsContext:string | undefined | null, prompt: string | undefined | null, fullOutput: string | undefined | null, 
    context_id: string | undefined | null, gptModel: string | undefined | null, marketName: string | undefined | null, sportName: string | undefined | null, tournamentName: string | undefined | null, 
    myEventName: string | undefined | null) => {
    const log: ILogFormSubmit =
    {
      timestamp: `${new Date().toISOString()}`,
      action_details: "form_submit",
      sport_context: `${sportsContext}`,
      prompt_input: `${prompt}`,
      system_output: `${fullOutput}`,
      context_id: `${context_id}`,
      model_name: `${gptModel}`,
      market: `${marketName}`,
      sport: `${sportName}`,
      tournament: `${tournamentName}`,
      match: `${myEventName}`
    }
    postLog(log)
  }

  export const logEmailSent = () => {
    const log: ILogEmailSent =
    {
      timestamp: `${new Date().toISOString()}`,
      action_details: "user_logged_in",
    }
    postLog(log)
  }