import React from 'react';
import Login from 'components/MainApp/Middle/Login/Login';
import Chat from 'components/MainApp/Middle/Chat/Chat';

interface MiddleProps {
  isLoggedIn: boolean;
}

const Middle = ({isLoggedIn}: MiddleProps) => {
  return (
    <>
      {isLoggedIn ? <Chat /> : <Login/>}
    </>
  )
  };

export default Middle;
