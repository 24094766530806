import React, { useContext, useState } from 'react';
import HistoryRow from 'components/MainApp/RightNav/HistoryRow';
import { IChatHistory } from 'interfaces/interfaces';
import { IStettingsState, appContext } from 'state/context';
import { ReactComponent as CloseButtonSVG } from 'assets/svg/closeButton.svg';

const RightNav = () => {
  const ctx = useContext(appContext);
  const { setSettingsState, settingsState, appState } = ctx || {};
  const { isRightNavOpen, isSmallScreen } = settingsState || {};
  const { chatsHistory } = appState || {};
  const [searchVal, setSearchVal] = useState("");

  const onChangeSearchVal = (e: any) => {
    setSearchVal(e.target.value);
  }

  const onCloseNav = () => {
    setSettingsState((prev: IStettingsState) => ({
      ...prev,
      isRightNavOpen: false,
    }))
  }

  const ChatsHistoryList =
    <>
      <div className="relative flex items-center">
        <input
          className={`custom-placeholder placeholder:font-normal w-full h-[48px] flex-1 rounded-2xl text-[16px] p-3 pr-10 leading-3 text-[#6B6B6B] hover:opacity-90 bg-[#EEEEEE] focus:outline-none
            focus:border-[#008944] border-2 border-[#CCCCCC]}`}
          type="text"
          placeholder="Search..."
          value={searchVal}
          onChange={onChangeSearchVal}
        />
      </div>
      <div className="relative top-0 flex border-b-2 border-[#CCCCCC] pb-2">
        <div className="flex w-full flex-col pt-2"/>
      </div>
      <div className="history-list flex-grow overflow-auto custom-scrollbar relative top-0 ">
        {chatsHistory && chatsHistory.map((chat: IChatHistory, index: number) => {
          if (chat.name.toLowerCase().includes(searchVal.toLowerCase())) { // zepsute wyszukiwanie
            return (
              <HistoryRow key={chat.id} chat={chat} index={index} />
            )
          } else {
            return null;
          }
        })}
      </div>
    </>

  const RightNavMobile = 
      <div
          data-testid={'right-nav-overlay'}
          className="absolute top-0 right-0 w-auto h-auto min-w-[100%] min-h-[100%] opacity-[99%] bg-transparent z-50"
          onClick={onCloseNav}>
          <div className="px-4 py-4 fixed top-0 right-0 flex h-full w-[296px] flex-none flex-col space-y-2 bg-[#111111] text-[14px] transition-all"
            onClick={e => e.stopPropagation()}>
            <div
              className='cursor-pointer pt-1 pl-2 pb-4 flex justify-end  w-[100%]'>
              <div data-testid={'close-button'} className='w-[25px]' onClick={onCloseNav}><CloseButtonSVG /></div>
            </div>
            {ChatsHistoryList}
          </div>
      </div>

  const RightNavWeb = 
      <div className="px-4 py-4 fixed top-0 left-0 flex h-full pt-[202px] w-[296px] flex-none flex-col space-y-2 bg-[#111111] text-[14px] transition-all sm:relative sm:top-0">
          {ChatsHistoryList}
      </div>
    

  return (
    <div data-testid={'right-nav-component'} className={`${isRightNavOpen ? "" : "hidden"}`}>
      {isSmallScreen ? (
        RightNavMobile
      ) : (
        RightNavWeb
      )}
    </div>
  )
  };

export default RightNav;
