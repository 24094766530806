import { IStettingsState, appContext } from "state/context";
import { useContext, useEffect, useState } from "react";
import ModalHeader from "components/common/Modals/modalParts/ModalHeader";
import ModalButton from "components/common/Modals/modalParts/ModalButton";
import { MODAL_TYPES } from "enums/modalTypes";
import RadioButton from "components/common/Modals/modalParts/RadioButton";
import { formatGPTString } from "utils/general";
import { commonContext } from "state/commonContext";

const GPTSettingsModal = () => {
    const ctx = useContext(appContext);
    const commonCtx = useContext(commonContext)
    const { setSettingsState } = ctx || {};
    const { isSmallScreen, gptModels, gptModel } = ctx?.settingsState || {};

    const { isOverLimit, isOverLimitGPT4 } = commonCtx?.commonCfgState || {};

    const [isSaving, setIsSaving] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');

    const onConfirmModel = () => {
        setIsSaving(true)
        setSettingsState((prevState: IStettingsState) => ({
            ...prevState,
            isOpenGPTSettingsModal: false,
            gptModel: selectedOption
        }))
        localStorage.setItem('gpt_model', selectedOption)
        setIsSaving(false)
    }

    const GPTList = () => {
        return (
            <div className='flex flex-col ml-4 mt-2 mr-4'>
                    <div className="relative flex flex-col items-start text-[#333333] font-normal text-1619 gap-y-2"> 
                    {gptModels.map((model: string, index: number) => {
                        const isMenuDisabled = (model === 'gpt3.5' && isOverLimit) || (model === 'gpt4' && isOverLimitGPT4) || (model === 'gpt4o' && isOverLimitGPT4)
                        if (isMenuDisabled) {
                            return;
                        }
                        return <RadioButton
                            key={index}
                            label={`${formatGPTString(model)}`}
                            name={`radio-button-${model}`}
                            checked={selectedOption === model}
                            onChange={() => setSelectedOption(model)}
                            disabled={isMenuDisabled}
                        />
                    })}
                    </div>
                </div>
        )
    }

    useEffect(() => {
        setSelectedOption(gptModel || '')
    }, [gptModel])

    return (
        <>
            <div className={`flex flex-col min-w-[270px] max-w-[352px] h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4`}
                style={{width: isSmallScreen ? "90%" : "40%" }}>
                <ModalHeader title="GPT Settings" modalType={MODAL_TYPES.gptSettings}/>
                <GPTList />
                <div id="gpt-model-button" className='mt-5 flex flex-row w-full justify-center'>
                    <ModalButton 
                        title="Confirm Choice" 
                        color="#008944" 
                        spinnerColor="#008944" 
                        isLoading={isSaving}  
                        w={304} 
                        h={48} 
                        roundSize={'rounded-2xl'}
                        onClick={onConfirmModel}/>

                </div>
            </div>
        </>
    )
  }

  export default GPTSettingsModal;
