import { streamGptOutput } from 'api/functions';
import { ReactComponent as InfoIcon } from 'assets/svg/infoButton.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { IAppContext, IStettingsState, appContext } from 'state/context';
import { autoExpandOnChange } from 'utils/general';
import { ReactComponent as SaveIcon } from 'assets/svg/saveIcon.svg';
import { logFormSubmit } from 'logs/logFunctions';


const ChatPromptInput = () => {
    const ctx = useContext(appContext);
    const {setAppState, appState} = ctx || {};
    const { sportsContext, prompt, market, sport, tournament, myEvent } = ctx?.appState || {};
    const [isPromptInfoClicked, setIsPromptInfoClicked] = useState(false);
    const promptRef = useRef<HTMLTextAreaElement>(null);
    const [activeDot, setActiveDot] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { context_id } = ctx?.trackContext || {};
    const { setSettingsState, settingsState} = ctx || {};
    const { gptModel } = settingsState || {};

    const onClickPromptInfo= () => {
        setIsPromptInfoClicked(true);
          setTimeout(() => {
            setIsPromptInfoClicked(false);
          }, 2000)
      }

      const openInfoModal = (text: string) => {
        setSettingsState((prev: IStettingsState) => ({
          ...prev,
          isOpenInfoModal: true,
          infoModalText: text
        }))
      }

      const submit = async () => {
        setIsLoading(true);
        if (appState && !appState.prompt) {
          openInfoModal('Please fill in the prompt input text field before pressing submit')
          setIsLoading(false);
          return;
        }
        if (!ctx?.appState.market.name || !ctx?.appState.myEvent.id) {
          openInfoModal('Please select an event')
          setIsLoading(false);
          return;
        }
    
        setAppState((prevState: any) => ({
          ...prevState,
          output: ""
        }));
    
        const bodyStream = await streamGptOutput({
          market: ctx.appState.market.name,
          event_id: ctx.appState.myEvent.id,
          user_prompt: ctx.appState.prompt,
          sports_context: ctx.appState.sportsContext || "",
          gpt_model: ctx.settingsState.gptModel,
        });
    
        if (bodyStream) {
          if (bodyStream.status === 400 ||  bodyStream.status === 431 || bodyStream.status === 414) {
              openInfoModal(bodyStream.detail)
              setIsLoading(false);
              return;
          }

          const reader = bodyStream.getReader();
          const decoder = new TextDecoder();
          let logOutput = ''; 
          reader.read().then(async function processText({ done, value }: any) {
            if (done) {
              logFormSubmit(sportsContext, prompt, logOutput, context_id, gptModel, market?.name, sport?.name, tournament?.name, myEvent?.name);
              setIsLoading(false);
              return;
            }
            const textChunk = decoder.decode(value, {stream: true});
            logOutput += textChunk;
            setAppState((prevState: IAppContext) => ({
              ...prevState,
              output: prevState.output + textChunk
            }));
            return reader.read().then(processText);
          });
        }
      };
   
      const onSaveChats = () => {
        if (!ctx?.appState.prompt) {
          openInfoModal("Please enter Prompt Input")
          setIsLoading(false);
          return;
        } else if (!ctx?.appState.market.name || !ctx?.appState.myEvent.id) {
          openInfoModal("Please select an event")
          setIsLoading(false);
          return;
        } else {
          setSettingsState((prev: any) => ({
            ...prev,
            isOpenSaveModal: true
          }))
        }
      }

    useEffect(() => {
    if (isLoading) {
        const interval = setInterval(() => {
        setActiveDot((prevActiveDot) => (prevActiveDot + 1) % 3);
        }, 300);

        return () => clearInterval(interval);
    } else {
        setActiveDot(0);
    }
    }, [isLoading]);

    useEffect(() => {
      if (!settingsState.isLeftNavOpen && !settingsState.isRightNavOpen && settingsState.isSmallScreen ) {
        promptRef.current?.focus()
      }
      if (settingsState.isLeftNavOpen && settingsState.isRightNavOpen && !settingsState.isSmallScreen ) {
        promptRef.current?.focus()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const handleResize = () => {
            if (promptRef.current) {
                promptRef.current.style.height = 'inherit';
                promptRef.current.style.height = `${(promptRef.current).scrollHeight+5}px`;
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ctx?.appState.prompt]);
    
    return  (
        <>

            <div className='flex flex-row flex-start mt-4 text-1619 text-[#CCCCCC] w-[100%] mb-2 ml-8 gap-2 h-[19px]'>
                  Prompt Input
                  {settingsState.isSmallScreen ? 
                  (
                  <div onClick={onClickPromptInfo}>
                    <InfoIcon />
                  </div>
                  ) 
                  :
                  (<div
                      onMouseEnter={() => setIsPromptInfoClicked(true)}
                      onMouseLeave={() => setIsPromptInfoClicked(false)}
                      >
                        <InfoIcon />
                  </div>
                  )}
                  {isPromptInfoClicked ? (
                    settingsState.isSmallScreen ? (
                      <div 
                        className={`relative text-[11px] text-[#EEEEEE] font-semibold flex justify-center items-center bg-[#2C2523] w-[200px] p-1 h-[80px]`}
                        style={{border: "1px solid #504C4A", zIndex: 999, top: 15, left: 5}}>
                          Enter a description in the 'Prompt Input' field to specify the type of text you want to generate or create.
                      </div>
                    ) :
                    (
                      <div 
                        className='relative top-8 -right-2 z-9999 text-[11px] text-[#EEEEEE] font-semibold
                                      flex justify-center items-center bg-[#2C2523] p-1 w-[250px] h-[70px]'
                        style={{border: "1px solid #504C4A", zIndex: 999}}>
                        Enter a description in the 'Prompt Input' field to specify the type of text you want to generate or create.
                      </div>
                    )
                  ) :
                  (settingsState.isSmallScreen ? 
                    (null)
                    : (
                        <div 
                          className='relative -top-5 z-15 text-[11px] text-[#EEEEEE] font-semibold w-auto
                                  flex justify-center items-center bg-transparent h-[19px]'>
                        </div>
                      )
                  )}
            </div>
            <div data-testid={'prompt'} className='relative w-[100%] min-h-[104px] h-auto border-2 border-[#CCCCCC] rounded-2xl px-4 pt-4 bg-[#EEEEEE]'>
                <div id="send-button" className={`absolute ${!activeDot && !isLoading ? "bottom-2 right-1 pb-1 pl-2 pt-2 pr-2 rounded-lg hover:bg-[#6B6B6B]/10"
                : "bottom-2 right-3"} cursor-pointer`} onClick={submit} title="Send">
                {!activeDot && !isLoading ? (
                    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_261_266)">
                        <path d="M23.1569 14.7827L2.28426 25.8195C1.24269 26.3705 0 25.5996 0 24.4053V17.4265C0 16.6706 0.520784 16.0191 1.24668 15.8657L12.8731 13.4146L1.24668 10.9622C0.520784 10.8088 0 10.1573 0 9.40137V1.59608C0 0.381381 1.27865 -0.386798 2.32155 0.202229L23.1942 11.9746C24.2864 12.5908 24.2651 14.1977 23.1569 14.7827Z" fill="#6B6B6B" />
                    </g>
                    <defs>
                        <clipPath id="clip0_261_266">
                        <rect width="24" height="26" fill="white" />
                        </clipPath>
                    </defs>
                    </svg>
                    
                ) : (
                    <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="8" height="8" rx="4" fill={activeDot === 0 ? '#008944' : '#6B6B6B'}/>
                    <rect x="12" width="8" height="8" rx="4" fill={activeDot === 1 ? '#008944' : '#6B6B6B'}/>
                    <rect x="24" width="8" height="8" rx="4" fill={activeDot === 2 ? '#008944' : '#6B6B6B'}/>
                    </svg>
                )}
                </div>
                <div
                id="save-button"
                className='absolute right-1.5 top-0.5 cursor-pointer pl-2 p-1.5 hover:bg-[#6B6B6B]/10 rounded-lg '
                onClick={onSaveChats}
                title="Save chat"
                >
                <SaveIcon />
                </div>
                <textarea id="prompt-textarea" className='w-[90%] resize-none text-[#6B6B6B] bg-[#EEEEEE] border-none focus:outline-none text-1619 custom-scrollbar-transparent'
                  ref={promptRef}
                  onChange={(e) => autoExpandOnChange(e, 'prompt', setAppState)}
                  style={{ height: 'auto', minHeight: '110px' }}
                  value={ctx?.appState.prompt}
                >
                </textarea>
            </div>
        </>
    )
};

export default ChatPromptInput;
