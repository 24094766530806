
import { IBBAndVS, IIsOverLimit, IPostLog, IStreamGptOutput } from "interfaces/interfaces";

export const sendEmail = async (email: string) => {
  try {
    const response = await fetch(`/api/send_email/${email}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });
    if (response) {
      let data = await response.json();
      if (response.status) {
        data.statusCode = response.status;
      }
      return data;
    } 
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getUserChats = async (): Promise<Object | null> => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`/api/user_chats`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('login failed');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getMarkets = async (): Promise<string[] | null> => {
  try {
    const response = await fetch(`/api/markets`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('getMarkets failed');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSports = async (market: string) => {
  try {
    const response = await fetch(`/api/sports/${market}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('getSports failed');
    }
  } catch (error) {
    console.error('getSports',error);
    return null;
  }
};

export const getTournaments = async (market: string, sport_id: number) => {
  try {
    const response = await fetch(`/api/tournaments/${market}/${sport_id}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('getTournaments failed');
    }
  } catch (error) {
    console.error('getTournaments',error);
    return null;
  }
};

export const getMyEvents = async (market: string, sport_id: number, tournament_id: number) => {
  try {
    const response = await fetch(`/api/events/${market}/${sport_id}/${tournament_id}`);

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('getMyEvents failed');
    }
  } catch (error) {
    console.error('getMyEvents',error);
    return null;
  }
};

export const getBBAndVS = async (market: string, eventId: number): Promise<IBBAndVS | null> => {
  let url = `/api/get_bb_and_vs/${market}/${eventId}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('getBBAndVS failed');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const streamGptOutput = async (params: IStreamGptOutput): Promise<any> => {
  const { market, event_id, user_prompt, sports_context, gpt_model } = params;
  let url = `/api/stream_gpt_output/${market}/${event_id}`;
  url += `?user_prompt=${encodeURIComponent(user_prompt)}&sports_context=${encodeURIComponent(sports_context)}&gpt_model=${encodeURIComponent(gpt_model)}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      return response.body; // return the ReadableStream
    } 
    else if (response.status === 400) {
      const errorResponse = await response.json();
      return {status: 400, detail: errorResponse.detail};
    } else if (response.status === 431 || response.status === 414) {
      return {status: response.status, detail: 'Input text is too long. Max allowed tokens: 512.'};
    }else {
      return {status: 400, detail: 'Something went wrong. Please try again.'};
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const deleteChat = async (chat_id: number): Promise<any> => {
  let url = `/api/chat/${chat_id}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      return true;
    } else if (!response.ok) {
      return response.status;
    } else {
      return false;
    }
};

export const postSaveChat = async (user_prompt: string, name: string): Promise<any> => {
    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          user_prompt: user_prompt,
          name: name
        }),
      });
      
      if (!response.ok) {
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
};

export const patchRenameChat = async (user_prompt: string, name: string, chatId: number): Promise<any> => {
    try {
      const response = await fetch(`/api/chat/${chatId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          user_prompt: user_prompt,
          name: name
        }),
      });
      
      if (!response.ok) {
        return response.status;
      }
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
};

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const postLog = async (log: IPostLog): Promise<any> => {
  try {
    const response = await fetch('/api/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        ...log
      }),
    });
    
    if (!response.ok) {
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
};

export const getProfile = async (): Promise<Object[]> => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`/api/profile`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return Array.isArray(data) ? data : [data];
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const getIsOverLimit = async (): Promise<IIsOverLimit | boolean | string> => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    const response = await fetch(`/api/is_over_limit`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status.toString().startsWith('5')) {
      return true;
    }
    if (response.ok) {
        const data = await response.json();
        return data;
      
    } else {
      return false;
    }
  } catch (error) {
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.log('error', error.message);
    return 'Failed to fetch'
    } else {
      return true;
    }
  }
}

export const getLeagueStatus = async (market: string, sport_id: number) => {
  
  try {
    const response = await fetch(`/api/leagues_status/${market}/${sport_id}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('getLeagueStatus failed');
    }
  } catch (error) {
    console.error('getLeagueStatus',error);
    return null;
  }
};

// type ResponseType = IIsOverLimit | boolean;

//RAndom response
// export const  getIsOverLimit = async(): Promise<IIsOverLimit | boolean> => {
//     const possibleResponses: ResponseType[] = [
//         // { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: false },
//         // { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: true },
//         { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: false },
//         // { limit_exceeded: true, show_error_page: true },
//         // { limit_exceeded: true, show_error_page: false },
//         // { limit_exceeded: false, show_error_page: true },
//     ];

//     const randomIndex = Math.floor(Math.random() * possibleResponses.length);
//     return possibleResponses[randomIndex];
// }

//** */

// type ResponseType = IIsOverLimit | boolean;

// let currentIndex = 0;

// //by order response
// export const getIsOverLimit = async (): Promise<ResponseType> => {
//     const possibleResponses: ResponseType[] = [
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: true },

// ];

//     const response = possibleResponses[currentIndex];
//     currentIndex = (currentIndex + 1) % possibleResponses.length; 
//     return response;
// }

// TEST CASES FOR FUTURE TESTS
//BOTH GPT3 AND GPT4 AVAILIBLE FOR USER
//tests/ gpt-3 active // gpt -4 active // deactivation of gpt3 - ok
//user using gpt3.5
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: false },

//tests/ gpt-3 active // gpt -4 active // deactivation of gpt3 - ok
//user using gpt4
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: false },

//tests/ gpt-3 active // gpt -4 active // deactivation of gpt4
//user using gpt3.5
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: true },


//tests/ gpt-3 active // gpt -4 active // deactivation of gpt4
//user using gpt4
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: true },

//tests/ gpt-3 active // gpt -4 active // deactivation of gpt4 and gpt3
//user using gpt3.5 
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: true },

//tests/ gpt-3 active // gpt -4 active // deactivation of gpt4 and gpt3
//user using gpt4
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: true },



//ONLY GPT3 AVAILIBLE FOR USER 
//tests/ gpt-3 active // gpt -4 active // deactivation of gpt3 
//user using gpt3.5
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: false },

//tests/ gpt-3 active // gpt -4 active // deactivation of gpt4 
//user using gpt3.5
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: true },

//tests/ gpt-3 active // gpt -4 active // deactivation of gpt4 and gpt3
//user using gpt3.5
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: true, show_error_page: false, limit_exceeded_gpt4: true },

//OTHER ERRORS CASES
//error page
// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: false, show_error_page: true, limit_exceeded_gpt4: false },

// { limit_exceeded: false, show_error_page: false, limit_exceeded_gpt4: false },
// { limit_exceeded: false, show_error_page: true, limit_exceeded_gpt4: false },


