import { useContext } from "react";
import ModalHeader from "components/common/Modals/modalParts/ModalHeader";
import { IStettingsState, appContext } from "state/context";
import { MODAL_TYPES } from "enums/modalTypes";

const VarsModal = () => {
    const ctx = useContext(appContext);
    const { setSettingsState, setAppState } = ctx || {};
    const {tournament, myEvent, bbAndVsData} = ctx?.appState || {};

    const modalRow = (value: string, name: any) => {
        const handleClick = () => {
          setAppState((prevState: any) => ({
            ...prevState,
            prompt: prevState.prompt + `{${value}}`
          }));

          setSettingsState((prev: IStettingsState) => ({
            ...prev,
            isOpenVarsModal: false
          }))
        }
    
        return (
          <div className={`p-2 pr-10 pl-4 flex flex-row justify-between items-center mb-2 border-b-2 hover:bg-[#CCCCCC]`}>
            <div className='flex flex-row w-[100%] justify-between'>
              <div className='min-w-[160px] text-1619 text-[#333333] font-bold'>{`{${value}}`}</div>
              <div className='pl-2 text-1619 text-[#6B6B6B] font-medium truncate max-w-[40%]'>{name}</div>
              <button
              className={`min-w-[130px] h-[40px] bg-[#008944] border-2 border-[#CCCCCC] text-[#EEEEEE] font-medium text-1619 rounded-xl active:bg-[#008944]/80 hover:bg-[#008944]/90`}
              onClick={handleClick}>
              Add
            </button> 
            </div>
          </div>
        )
      };
    
    return (
        <div className={`flex flex-col w-[40%] min-w-[640px] max-w-md h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4`}>
            <ModalHeader title="VALUES/VARS" modalType={MODAL_TYPES.varsModal}/>
            <div className='flex flex-col ml-4 mt-2 mr-4'>
                {modalRow("TOURNAMENT", tournament?.name)}
                {modalRow("MATCH", myEvent?.name)}
                {modalRow("HOME", myEvent?.name?.split(" - ")[0])}
                {modalRow("AWAY", myEvent?.name?.split(" - ")[1])}
                {modalRow("VALUESTATS", bbAndVsData?.vs)}
                {modalRow("MOSTBACKED", bbAndVsData?.bb)}
            </div>
        </div>
    )
  }

  export default VarsModal;
