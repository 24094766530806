import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DropDownArrow } from 'assets/svg/dropDownArrow.svg';
import { IStettingsState, ITrackContext, appContext } from 'state/statusPageContext';
import { MyEvent, Sport, Tournament } from 'interfaces/interfaces';
import { FILTER_TYPES } from 'enums/filterTypes';
import { generateUUID } from 'utils/general';

interface Option {
  id: number;
  name: string;
  audio?: string;
}

interface DropdownDivProps {
  title: string;
  options?: Option[];
  defaultItem?: Sport | Tournament | MyEvent | null;
  nav?: boolean;
  setValue?: Function;
  disabled?: boolean;
  small?: boolean
}

const SpDropdownDiv = ({ options, title = "Select", defaultItem, setValue, disabled }: DropdownDivProps) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [dropdownOptions, setDropdownOptions] = useState<any>(options);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [clicked, setClicked] = useState(false);
  const ctx = useContext(appContext);
  const { setAppState, setSettingsState = () => {}, setTrackContext } = ctx || {};
  const { activeDropdown, isSmallScreen } = ctx?.settingsState || {};

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);

    const filtered = dropdownOptions.filter((option: any) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOptions(filtered);
  };

  const setNewUUID = () => {
    const UUID = generateUUID();
      setTrackContext((prevState: ITrackContext) => ({
        ...prevState,
        context_id: UUID
      }));
    }

  const handleOptionSelect = (option: Option) => {
    setNewUUID()
    switch (title) {
      case FILTER_TYPES.SPORT:
        setAppState((prevState: any) => ({
          ...prevState,
          defaultFilters: {
            ...prevState.defaultFilters,
            sport: option
          }
        }));
        break;
      case FILTER_TYPES.TOURNAMENT:
        setAppState((prevState: any) => ({
          ...prevState,
          defaultFilters: {
            ...prevState.defaultFilters,
            tournament: option
          }
        }));
        break;
      case FILTER_TYPES.MATCH:
        setAppState((prevState: any) => ({
          ...prevState,
          defaultFilters: {
            ...prevState.defaultFilters,
            myEvent: option
          }
        }));
        break;
        case FILTER_TYPES.MARKET:
        setAppState((prevState: any) => ({
          ...prevState,
          defaultFilters: {
            ...prevState.defaultFilters,
            market: option
          }
        }));
        break;
      default:
        break;
    }
  
    setInputValue(option.name);
    setShowResults(false);
    setValue && setValue(option);
  };

  const handleTriangle = (e?: any) => {
    setSettingsState((prevState: IStettingsState) => ({
      ...prevState,
      activeDropdown: title,
    }));

    if (!disabled) {
      if (e) {
        handleListClick(e)
      }
      options && setFilteredOptions(options);
      setShowResults((showResults) => !showResults);
      setClicked((clicked) => !clicked);
    }
  }

  const handleListClick = (event: any) => {
    event.stopPropagation();
  };

  const checkIfDefaultItemExists = (options: Sport[] | Tournament[] | MyEvent[] | null , defaultItem: Sport | Tournament | MyEvent | null | undefined): boolean => {
    if (title === FILTER_TYPES.MARKET) {
      if (options?.some(option => option.name === defaultItem?.name)) {
        return true;
      }
    }
    if (options?.some(option => option.name === defaultItem?.name && option.id === defaultItem?.id)) {
      return true;
    }
    else {
      return false;
    }
  };

  useEffect(() => {
    if (dropdownOptions && dropdownOptions.length > 0) {
      const isDefaultItemInList = checkIfDefaultItemExists(dropdownOptions, defaultItem);
      setInputValue(isDefaultItemInList ? defaultItem?.name : dropdownOptions[0].name);
      setValue && setValue(isDefaultItemInList ? defaultItem : dropdownOptions[0])
      setFilteredOptions(dropdownOptions);
    } else if (dropdownOptions && dropdownOptions.length === 0) {
      setInputValue('');
      setFilteredOptions([]);
      setValue && setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOptions]);

  useEffect(() => {
    if (options && options.length > 0) {
      setDropdownOptions(options);
    } else if (options && options.length === 0) {
      setDropdownOptions([]);
    }
    setClicked(false);
  }, [options])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowResults(false);
        setClicked(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div data-testid={'dropdown-div'} className='max-w-[296px] w-[100%]'>
      <div data-testid={`dropdown-${title}`} className=''>
        <div className='mb-2 ml-4 text-1619 font-normal text-[#CCCCCC]'>{title}</div>
        <div className="relative">
          <div className={` bg-[#EEEEEE] rounded-2xl focus:outline-none mb-4 ${clicked ? 'border-2 border-[#008944]' : 'border-2 border-[#CCCCCC]'} `}>
            <div
              data-testid={`dropdown-input-${title}`}
              className={`${inputValue} flex justify-start items-center truncate w-[85%] h-[48px] text-1619 text-[#6B6B6B] bg-[#EEEEEE] rounded-2xl focus:outline-none pl-4 cursor-pointer`}
              ref={inputRef}
              onChange={handleInputChange}
              onClick={handleTriangle}>
              <div className={`truncate w-[98%]`} style={{color: disabled ? '#a4aab5' : '#6B6B6B' }}>{disabled ? "Disabled - missing data" : inputValue}</div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-2 flex items-center justify-center pr-3 cursor-pointer "
                onClick={(e) => handleTriangle(e)} >
            <DropDownArrow data-testid={'dropdown-arrow'}/>
          </div>
        </div>
        {filteredOptions.length > 0 && showResults && activeDropdown === title && (
        <div 
          id="dropdown-list"
          className={`pt-3 pb-3 absolute z-10 border-2 border-[#CCCCCC] bg-[#EEEEEE] rounded-2xl w-[100%] text-1619 text-[#6B6B6B] max-h-[30%] overflow-y-auto scrollbar-green` }
          style={{marginTop: "-0.5rem", maxHeight: isSmallScreen ? "20%" : "30%"}}   
          onClick={handleListClick}
        >
          {filteredOptions.map((option: any) => {
            const noCurrentData = option.name.includes("no current data");
            return (
            noCurrentData ? (
              <div 
                className={`pl-4 pr-4 pt-1 pb-1 hover:bg-[#CCCCCC] hover:text-[#EEEEEE]`}key={option.name}>
                {option.name}
              </div>
            )
            : (
                <div 
                className={`pl-4 pr-4 pt-1 pb-1 hover:bg-[#008944] hover:text-[#EEEEEE] cursor-pointer`} 
                key={option.name} 
                onClick={() => handleOptionSelect(option)}>
                {option.name}
              </div>
            ))
          })}
        </div>
        )}
      </div>
    </div>
  );
};

export default SpDropdownDiv;
