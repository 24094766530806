import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import StatusMainPage from 'pages/Status/StatusMainPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/status",
    element: <StatusMainPage/>
    // element: <App/>
  },
  {
    path: "*",
    element: <App/>
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    // <App />
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
