
interface RadioButtonProps {
  label?: string;
  name: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

function RadioButton({ label, name, checked, onChange, disabled = false }: RadioButtonProps) {
  return (
    <label id={name} className="inline-flex items-center cursor-pointer">
      <input
        type="radio"
        className="hidden"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={`flex items-center justify-center w-6 h-6 border-2 border-[#6B6B6B] rounded-full bg-white`}>
        {checked && <span className="w-4 h-4 bg-[#6B6B6B] rounded-full"></span>}
      </span>
      {label && <span className="ml-2 text-[#6B6B6B]">{label}</span>}
    </label>
  );
}

export default RadioButton;