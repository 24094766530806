import { useContext } from "react";
import DeleteModal from "components/common/Modals/DeleteModal";
import InfoModal from "components/common/Modals/InfoModal";
import RenameChatModal from "components/common/Modals/RenameChatModal";
import SaveChatModal from "components/common/Modals/SaveChatModal";
import VarsModal from "components/common/Modals/VarsModal";
import { appContext } from "state/context";
import GPTSettingsModal from "components/common/Modals/GPTSettingsModal";

const RenderModals = () => {
    const ctx = useContext(appContext);
    const { isOpenInfoModal, isOpenDeleteModal, isOpenRenameModal, isOpenSaveModal, isOpenVarsModal, isOpenGPTSettingsModal } = ctx?.settingsState || {};

    const modalsToRender = [
        {isOpen: isOpenSaveModal, Modal: SaveChatModal},
        {isOpen: isOpenRenameModal, Modal: RenameChatModal},
        {isOpen: isOpenDeleteModal, Modal: DeleteModal},
        {isOpen: isOpenInfoModal, Modal: InfoModal},
        {isOpen: isOpenVarsModal, Modal: VarsModal},
        {isOpen: isOpenGPTSettingsModal, Modal: GPTSettingsModal}
    ];

    return (
        <>
            {modalsToRender.map(({ isOpen, Modal }, index) => {
                if (!isOpen) return null;
        
                return (
                    <div key={index} style={{ zIndex: 51 }} className="fixed top-0 left-0 h-screen w-screen bg-[#D9D9D9BF] flex justify-center items-center">
                        <Modal />
                    </div>
                );
            })}
        </>
    );
};

export default RenderModals;
