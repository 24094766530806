import React, { useContext, useEffect, useState } from 'react';
import DropdownDiv from 'components/MainApp/LeftNav/SPDropdownDiv';
import { SportFP, TournamentFP, MyEventFP, Sport } from 'interfaces/interfaces';
import Spinner from 'components/common/Modals/modalParts/Spinner';
import { getMarkets, getMyEvents, getSports, getTournaments } from 'api/functions';
import { convertStringArrayToObject } from 'utils/general';
import { IAppContext, IStettingsState, appContext } from 'state/context';
import { FILTER_TYPES } from 'enums/filterTypes';
import { ReactComponent as CloseButton } from 'assets/svg/closeButton.svg';
import { MAX_WIDTH_SMALL_SCREEN } from 'enums/others';

const LeftNav  = () => {
  const ctx = useContext(appContext);
  const [sports, setSports ] = useState<SportFP[]>();
  const [sport, setSport ] = useState<SportFP>()
  const [tournaments, setTournaments ] = useState<TournamentFP[]>();
  const [tournament, setTournament ] = useState<TournamentFP>()
  const [myEvents, setMyEvents ] = useState<MyEventFP[]>(); 
  const [myEvent, setMyEvent ] = useState<MyEventFP>() 
  const [isLoadingMyEvents, setIsLoadingMyEvents ] = useState<boolean>(false)
  const [isLoadingTournaments, setIsLoadingTournaments ] = useState<boolean>(false)
  const [isLoadingSports, setIsLoadingSports ] = useState<boolean>(false)
  const [markets, setMarkets] = useState<any[]>();
  const [market, setMarket] = useState<any>();
  const [isLoadingMarkets, setIsLoadingMarkets ] = useState<boolean>(false)
  const { setSettingsState, setAppState} = ctx || {};
  const { isLeftNavOpen, isSmallScreen } = ctx?.settingsState || {};
  const {market: appStateMarket, sport: appStateSport,
        tournament: appStateTournament, defaultFilters, markets: appStateMarkets} = ctx?.appState || {};

  const closeNav = () => {
    setSettingsState((prev: IStettingsState) => ({
      ...prev,
      isLeftNavOpen: false,
    }))
  }

  const isTournamentEmptyArray = async (sport: Sport): Promise<boolean> => {
    if (appStateMarket?.name && sport.id) {
      const tournament = await getTournaments(appStateMarket?.name, sport.id);
      if (!tournament || tournament.length === 0) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return false;
    }
  }

  const Filters = 
    <>
      {(markets || appStateMarkets )&& !isLoadingMarkets ?
      <DropdownDiv
        title={FILTER_TYPES.MARKET}
        options={markets ?? appStateMarkets}
        setValue={setMarket}
        defaultItem={defaultFilters?.market}
        />
      :
      <DropdownDiv title={FILTER_TYPES.MARKET} disabled={true} nav={true} />}
    {isLoadingMarkets ? <div data-tesid={`spinner-${FILTER_TYPES.MARKET}`} className='self-start ml-5 mt-0 mb-2'><Spinner /></div> : <div className='self-start mt-0 mb-3' />}

    {sports && !isLoadingSports ?
      <DropdownDiv
        title={FILTER_TYPES.SPORT}
        options={sports}
        setValue={setSport}
        disabled={sports?.length === 0 || !sports}
        defaultItem={defaultFilters?.sport}
      />
      :
      <DropdownDiv title={FILTER_TYPES.SPORT} disabled={true} />}
    {isLoadingSports ? <div data-tesid={`spinner-${FILTER_TYPES.SPORT}`} className='self-start ml-5 mt-0 mb-2'><Spinner /></div> : <div className='self-start mt-0 mb-3' />}

    {tournaments && !isLoadingTournaments && !isLoadingSports ?
      <DropdownDiv
        title={FILTER_TYPES.TOURNAMENT}
        options={tournaments}
        setValue={setTournament}
        disabled={tournaments?.length === 0 || !tournaments}
        defaultItem={defaultFilters?.tournament} />
      :
      <DropdownDiv title={FILTER_TYPES.TOURNAMENT} disabled={true} />}
    {isLoadingTournaments ? <div data-tesid={`spinner-${FILTER_TYPES.TOURNAMENT}`} className='self-start ml-5 mt-0 mb-2'><Spinner /></div> : <div className='self-start mt-0 mb-3' />}

    {myEvents && !isLoadingTournaments && !isLoadingSports && !isLoadingMyEvents ?
      <DropdownDiv
        title={FILTER_TYPES.MATCH}
        options={myEvents}
        setValue={setMyEvent}
        disabled={myEvents?.length === 0 || !myEvents}
        defaultItem={defaultFilters?.myEvent} />
      :
      <DropdownDiv title={FILTER_TYPES.MATCH} disabled={true} />}
    {isLoadingMyEvents ? <div data-tesid={`spinner-${FILTER_TYPES.MATCH}`} className='self-start ml-5 mt-0 mb-2'><Spinner /></div> : <div className='self-start mt-0 mb-3' />}
  </>

  const FiltersContent =
    <div className="flex-grow overflow-auto custom-scrollbar relative top-0 h-full">
      {isSmallScreen ? (
         <div className={`flex flex-col items-center justify-center mt-[59px]`}>
          {Filters}
       </div>
      ) : (
        <div className={`flex flex-col items-center justify-center mt-[159px]`}>
          {Filters}
      </div>
      )}
    </div>

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        if (appStateMarket?.name === null) {
          setIsLoadingMarkets(true);
          const data = await getMarkets();
          if (data) {
            const convertedArray = convertStringArrayToObject(data);
            setMarkets(convertedArray);
            setAppState((prevState: IAppContext) => ({
              ...prevState,
              markets: convertedArray
            }));
          } else {
            console.log('getMarkets Error');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingMarkets(false);
      }
    };
  
    fetchMarkets();
  }, [appStateMarket]);

  useEffect(() => {
    if (market) {
      localStorage.setItem('default_market', JSON.stringify(market));
      setAppState((prevState: any) => ({
        ...prevState,
        market: market 
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market]);

  useEffect(() => {
    const fetchSports = async () => {
      try {
        setIsLoadingSports(true);
        const marketName = appStateMarket?.name;
        if (marketName) {
          const data = await getSports(marketName);
          if (data) {
            const newSports = await Promise.all(data.map(async (sport: Sport) => {
              const isTournamentEmpty = await isTournamentEmptyArray(sport);
              if (isTournamentEmpty) {
                return {
                  ...sport,
                  name: sport.name + ' (no current data)'
                };
              } else {
                return sport;
              }
            }));
            setSports(newSports);
          } else {
            console.log('getSports Error');
          }
        } else {
          setSports([]);
        }
      } catch (error) {
        console.error('getSports', error);
      } finally {
        setIsLoadingSports(false);
      }
    };
  
    fetchSports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStateMarket]);

  useEffect(() => {
    setAppState((prevState: any) => ({
        ...prevState,
        sport: sport
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        setIsLoadingTournaments(true);
        if (appStateMarket && appStateMarket?.name && appStateSport?.id) {
          const data = await getTournaments(appStateMarket.name, appStateSport?.id);
          if (data) {
            setTournaments(data);
          } else {
            console.log('getTournaments Error');
          }
        } else {
          setTournaments([]);
        }
      } catch (error) {
        console.error('getTournaments', error);
      } finally {
        setIsLoadingTournaments(false);
      }
    };
  
    fetchTournaments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStateMarket, appStateSport]);

  useEffect(() => {
    setAppState((prevState: any) => ({
        ...prevState,
        tournament: tournament ? tournament : {id: null, name: null}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament]);

  useEffect(() => {
    const fetchMyEvents = async () => {
      try {
        setIsLoadingMyEvents(true);
        if (appStateMarket && appStateMarket?.name && appStateSport?.id && appStateTournament?.id) {
          const data = await getMyEvents(appStateMarket?.name, appStateSport?.id, appStateTournament?.id);
          if (data) {
            setMyEvents(data);
          } else {
            console.log('getMyEvents Error');
          }
        } else {
          setMyEvents([]);
        }
      } catch (error) {
        console.error('getMyEvents', error);
      } finally {
        setIsLoadingMyEvents(false);
      }
    };
  
    fetchMyEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStateTournament, appStateMarket, appStateSport]);  

  useEffect(() => {
    setAppState((prevState: any) => ({
        ...prevState,
        myEvent: myEvent ? myEvent : {id: null, name: null}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myEvent]);

  useEffect(() => {
    if (window.innerWidth < MAX_WIDTH_SMALL_SCREEN) {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        isSmallScreen: true
      }))
    } else {
      setSettingsState((prev: IStettingsState) => ({
        ...prev,
        isSmallScreen: false
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeftNavOpen])

  
  return (
    <div data-testid={'left-nav-component'} className={`${isLeftNavOpen ? "" : "hidden"}`}>

      {isSmallScreen ?
        (
          <div
            className="absolute top-0 left-0 w-auto h-auto min-w-[100%] min-h-[100%] opacity-[99%] z-50 "
            onClick={closeNav}
          >
            <div className="px-4 py-4 fixed top-0 left-0 flex h-[100%] w-[296px] flex-none flex-col space-y-2 bg-[#111111] text-[14px] transition-all" 
              onClick={e => e.stopPropagation()}>
              <div
                className='cursor-pointer w-[25px] z-50'
                onClick={closeNav}>
                <CloseButton />
              </div>
              <div className='relative h-full'
              >{FiltersContent}</div>
            </div>
          </div>
        )
        :
        (
          <div className="px-4 py-4 fixed top-0 right-0 flex h-full w-[296px] flex-none flex-col space-y-2 bg-[#111111] text-[14px] transition-all sm:relative sm:top-0">
            {FiltersContent}
          </div>
        )}
    </div>
  )
  };

export default LeftNav ;


