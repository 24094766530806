import { IStettingsState, appContext } from "state/context";
import { useContext, useEffect, useState } from "react";
import ModalHeader from "components/common/Modals/modalParts/ModalHeader";
import ModalButton from "components/common/Modals/modalParts/ModalButton";
import { getUserChats, patchRenameChat, postSaveChat } from "api/functions";
import { MODAL_TYPES } from "enums/modalTypes";
import { logSavePrompt } from "logs/logFunctions";
import { FORBIDDEN_CHATS_NAME_LIST } from "enums/forbiddenChatsNameList";

const SaveChatModal = () => {
    const ctx = useContext(appContext);
    const { chatsHistory, prompt } = ctx?.appState || {};
    const { setAppState, setSettingsState } = ctx || {};
    const { inputIdToRename, inputValToRename, isSmallScreen } = ctx?.settingsState || {};
    const [inputVal, setInputVal] = useState("")
    const [message, setMessage] = useState("")
    const [isSaving, setIsSaving] = useState(false)
    const [isOverwrite, setIsOverwrite] = useState(false)
    const { context_id } = ctx?.trackContext || {};

    const onChangeVal = (e:any) => {
        setMessage("")
        setInputVal(e.target.value)
    }

    const onClickSave = async () => {
        const inputTrimmed = inputVal.trimEnd()
        if (FORBIDDEN_CHATS_NAME_LIST?.some(chat => chat === inputTrimmed)) {
            setMessage("This name is already used in the chat history");
            setIsSaving(false)
            return;
        }
        if (chatsHistory?.some((chat: any) => chat.name === inputTrimmed)) {
            setSettingsState((prev: IStettingsState) => ({
                ...prev,
                inputIdToRename: chatsHistory.find((chat: any) => chat.name === inputTrimmed)?.id,
                // inputValToRename: inputVal
            }))
            setIsOverwrite(true)
            return
        }
        setIsSaving(true)
        if (prompt && inputTrimmed) {
            const saveChat = await postSaveChat(prompt, inputTrimmed)
            if (saveChat) {
                setIsSaving(false)
                setSettingsState((prev: any) => ({
                    ...prev,
                    isOpenSaveModal: false
                }))
                const chats = await getUserChats()
                if (chats) {
                    setAppState((prevState: any) => ({
                        ...prevState,
                        chatsHistory: chats
                    }));
                } 
                logSavePrompt(context_id, inputVal, prompt)
            } else {
                setIsSaving(false)
                setMessage("Saving chat error, try again")
            }
        } else {
            setIsSaving(false)
            setMessage("Please enter a name")
        }
    }

    const onClickOverwrite = async () => {
        const inputTrimmed = inputVal.trimEnd()
        setIsSaving(true)
        if (prompt && inputTrimmed && inputIdToRename) {
            const updateChat = await patchRenameChat(prompt, inputTrimmed, inputIdToRename)
            if (updateChat) {
                const chats = await getUserChats()
                if (chats) {
                    setAppState((prevState: any) => ({
                        ...prevState,
                        chatsHistory: chats
                    }));
                    setSettingsState((prev: any) => ({
                        ...prev,
                        isOpenSaveModal: false
                    }))
                    setIsSaving(false)
                } 
                logSavePrompt(context_id, inputVal, prompt)
            } else {
                setIsSaving(false)
                setMessage("System chat cannot be renamed")
            }
        } else {
            setIsSaving(false)
            if (!inputTrimmed) {
                setMessage("Please enter a name")
            }
            if (!prompt) {
                setMessage("Please back and enter a prompt")
            }
        }
    }

    const onClickCancel = () => {
        setInputVal("")
        setSettingsState((prev: any) => ({
            ...prev,
            isOpenSaveModal: false
          }))
    }

    const onCancelOverwrite = () => {
        setIsOverwrite(false)
    }

    useEffect(() => {
            setInputVal(inputValToRename) 
    }, [inputValToRename])


    return (
        <>
        {!isOverwrite ? (
            <div id="save-modal" className="flex flex-col min-w-[270px] max-w-md h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4"
                 style={{width: isSmallScreen ? "90%" : "40%" }}>
                <ModalHeader title="Save chat to history" modalType={MODAL_TYPES.saveHistory}/>
                <div className='flex flex-col ml-4 mt-2 mr-4'>
                <div className='min-w-[160px] text-1619 text-[#333333] font-bold mb-4'>Name:</div>
                <div className="relative flex flex-col items-center ">
                    <input 
                    id="save-input"
                    className={`placeholder:font-normal w-full min-h-[48px] flex-1 rounded-2xl text-[16px] p-3 pr-10 leading-3 text-[#6B6B6B] hover:opacity-90 bg-[#EEEEEE] focus:outline-none
                    focus:border-[#008944] border-2 border-[#CCCCCC]}`}
                    type="text" 
                    autoFocus
                    value={inputVal}
                    placeholder="Enter name" 
                    onChange={(e) => onChangeVal(e)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onClickSave()
                        }
                    }}
                    />
                    {<div className="ml-1 mt-1 self-start text-[#A12300] text-1214">{message}</div>}
                </div>
                </div>
                <div className='mt-5 flex flex-row w-full justify-end'>
                    <ModalButton onClick={onClickCancel} title="Cancel" color="#A4A4A4"/>
                    <ModalButton onClick={onClickSave} title="Save" color="#008944" isLoading={isSaving}/>
                </div>
            </div>
            )
            :(
            <div className={`flex flex-col min-w-[270px] max-w-md h-auto bg-[#EEEEEE] rounded-2xl shadow-lg shadow-[#00000040] pt-4 pb-4`}
                 style={{width: isSmallScreen ? "90%" : "40%" }}>
                <ModalHeader title={"Overwrite " + inputVal.trimEnd()} modalType={MODAL_TYPES.saveHistory}/>
                <div className='flex flex-col ml-4 mt-2 mr-4'>
                <div className="relative flex flex-col items-start text-[#333333] font-bold"> 
                   <div> Are you sure?</div>
                    {<div className="ml-1 mt-1 self-start text-[#A12300] text-1214">{message}</div>}
                </div>
                </div>
                <div className='mt-5 flex flex-row w-full justify-end'>
                    <ModalButton onClick={onCancelOverwrite} title="Cancel" color="#A4A4A4" />
                    <ModalButton onClick={onClickOverwrite} title="Overwrite" color="#A12300" spinnerColor="#A12300" isLoading={isSaving} useOn={'Enter'}/>
                </div>
            </div>
            )}
        </>
    )
  }

  export default SaveChatModal;
