import ModalHeader from 'components/common/Modals/modalParts/ModalHeader';
import { MODAL_TYPES } from 'enums/modalTypes';
import React, { useContext, useRef } from 'react'
import { appContext, IStettingsState } from 'state/statusPageContext';
import { ReactComponent as GPTLogo } from 'assets/svg/gptLogo.svg';
import { getUserEmailFromJWT, openFeedbackWebsite } from 'utils/general';
import { logFeedbackNudgingAccepted } from 'logs/logFunctions';
import { commonContext } from 'state/commonContext';

export default function SPFeedbackNudging() {
    const ctx = useContext(appContext);
    const { setSettingsState } = ctx || {};
    const agreeRef = useRef<null | HTMLButtonElement>(null);
    const commonCtx = useContext(commonContext);
    const {setCommonCfgState } = commonCtx || {};

    const onAgree = () => {
        logFeedbackNudgingAccepted()
        openFeedbackWebsite()
        setCommonCfgState((prevState: IStettingsState) => ({
            ...prevState,
            isFeedbackNudgingOpen: false
        }))
    }
    return (
        <div
            style={{ zIndex: 51 }}
            className='absolute top-0 left-0 w-full h-full bg-[#D9D9D9BF] flex justify-center items-start p-4 '>
            <div style={{ boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)' }}
                className='mt-[12vh] w-[402px] h-[296px] bg-[#EEEEEE] flex flex-col items-center jusitfy-start
                        rounded-[16px] border-2 border-[#CCCCCC] pt-4'>
                <div style={{ zIndex: 52, width: '100%' }}><ModalHeader title="" modalType={MODAL_TYPES.FeedbackNudging} /></div>
                <div className='leadeing-normal -mt-8 w-full h-full flex flex-col justify-start items-center text-center
                            text-[#6B6B6B] font-normal text-1619'>
                    <GPTLogo className='mb-4' />
                    <div className='font-bold'>Your Experience Matters to Us,</div>
                    <div className='font-bold'>{getUserEmailFromJWT()}</div>
                    <br />
                    <div>Could we have done something better?</div>
                    <div>Tell us your thoughts and help shape our future.</div>
                    <div>It’s your ideas that make us grow!</div>
                    <button
                        data-testid={'feedbacknudging-agree-button'}
                        ref={agreeRef}
                        className={`bg-[#008944] w-[320px] h-[48px] rounded-[16px] 
                                                text-white text-1619 active:bg-[#008944]/80 hover:bg-[#008944]/90 cursor-pointer
                                                mt-4`}
                        style={{ border: "2px solid #CCCCCC" }}
                        onClick={onAgree}>
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}
