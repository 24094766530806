import { IStettingsState, appContext } from "state/context";
import { useContext } from "react";
import { MODAL_TYPES } from "enums/modalTypes";
import { ReactComponent as CloseButton } from 'assets/svg/closeButton.svg';
import { logFeedbackNudgingClosed } from "logs/logFunctions";
import { commonContext } from "state/commonContext";

interface ModalHeaderProps {
    title: string;
    modalType: MODAL_TYPES;
}

const ModalHeader = ({ title, modalType }: ModalHeaderProps) => {
    const ctx = useContext(appContext);
    const { setSettingsState } = ctx || {};
    const commonCtx = useContext(commonContext);
    const {setCommonCfgState } = commonCtx || {};


    const onClose = () => {
        if (modalType === MODAL_TYPES.FeedbackNudging) {
            logFeedbackNudgingClosed()
            setCommonCfgState((prev: IStettingsState) => ({
                ...prev,
                [modalType]: false
            }))
        } else {
            setSettingsState((prev: IStettingsState) => ({
                ...prev,
                [modalType]: false
            }))
        }
    }

    return (
        <div className='flex flex-row justify-between w-full pb-4'>
            <div className='pl-4 text-1619 text-[#6B6B6B] font-normal'>{title}</div>
            <div
                className="pr-4 cursor-pointer"
                onClick={onClose}>
                <CloseButton />
            </div>
        </div>
    )
}

export default ModalHeader;