import React, { useContext, useRef } from 'react';
import { appContext } from 'state/context';
import { openFeedbackWebsite } from 'utils/general';
import { logFeedbackPressed } from 'logs/logFunctions';
import { commonContext } from 'state/commonContext';

const ChatFooter = () => {
    const ctx = useContext(appContext);
    const commonCtx = useContext(commonContext);
    const { context_id } = ctx?.trackContext || {};
    const { isFeedbackNudgingOpen } = commonCtx?.commonCfgState || {};

    const onClickFeedback = () => {
        logFeedbackPressed(context_id)
        openFeedbackWebsite()
    }

    const FeedbackNudgeCover = () => {
        return isFeedbackNudgingOpen && (
            <div className='absolute top-0 left-0 w-full h-full bg-[#D9D9D9BF]'
                style={{zIndex: 51}}
            />
        )
      };

    const Feedback = () => {
        return (
            <div className="h-[100px] flex flex-col items-center justify-end p-1 mb-1 flex-1 text-[#CCCCCC] text-1214 gap-1">
                <p>GPT can make mistakes. Consider checking important information.</p>
                <p className='underline cursor-pointer' onClick={onClickFeedback}>Give your Feedback</p>
            </div>
        )
    }

    return (
        <div className='relative'>
        <FeedbackNudgeCover />
        <Feedback />
    </div>
        
    );
};

export default ChatFooter;
