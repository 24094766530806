import { getBBAndVS, getUserChats } from 'api/functions';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IAppContext, IStettingsState, appContext } from 'state/context';
import ChatNavHeader from 'components/MainApp/Middle/Chat/ChatNavHeader';
import ChatFooter from 'components/MainApp/Middle/Chat/ChatFooter';
import ChatMainHeader from 'components/MainApp/Middle/Chat/ChatMainHeader';
import ChatSportsContext from 'components/MainApp/Middle/Chat/ChatSportsContext';
import ChatPromptInput from 'components/MainApp/Middle/Chat/ChatPromptInput';
import ChatOutput from 'components/MainApp/Middle/Chat/ChatOutput';
import FeedbackNudging from 'components/MainApp/Middle/Chat/FeedbackNudging';
import { logDropdownChange } from 'logs/logFunctions';
import { commonContext } from 'state/commonContext';

const Chat = () => {
  const ctx = useContext(appContext);
  const commonCtx = useContext(commonContext);
  const chatRef = useRef<HTMLDivElement>(null);
  const { isSmallScreen } = ctx?.settingsState;
  const { isFeedbackNudgingOpen } = commonCtx?.commonCfgState || {};
  const { myEvent, sport, tournament, market, bbAndVsData } = ctx?.appState || {};
  const { setAppState, setSettingsState } = ctx || {};
  const { context_id } = ctx?.trackContext || {};
  const [logReadyToSend, setLogReadyToSend] = useState(false);

  useEffect(() => {
    const fetchBBAndVS = async () => {
      setAppState((prevState: IAppContext) => ({
        ...prevState,
        bbAndVsData: { bb: "", vs: "" }
      }));
      if (market?.name && myEvent?.id) {
        const data = await getBBAndVS(market.name, myEvent.id);
        if (data) {
          setAppState((prevState: any) => ({
            ...prevState,
            bbAndVsData: data,
          }));
        }
        const chats = await getUserChats()
        if (chats) {
          setAppState((prevState: any) => ({
            ...prevState,
            chatsHistory: chats
          }));
        }
      }
    };
    fetchBBAndVS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myEvent?.id, market?.name]);

  useEffect(() => {
    setAppState((prevState: IAppContext) => ({
      ...prevState,
      sportsContext: `${market?.name ? '- Market: ' + market?.name + '\n' : ''}${sport?.name ? '- Sport: ' + sport?.name + '\n' : ''}${tournament?.name ? '- Tournament/League: ' + tournament?.name + '\n' : ''}${myEvent?.name ? '- Match: ' + myEvent?.name + '\n' : ''}${bbAndVsData?.bb ? '- ' + bbAndVsData?.bb + '\n' : ""}${bbAndVsData?.vs ? '- ' + bbAndVsData?.vs : ""}`
      // isPromptFocus: true
    }));
    setSettingsState((prevState: IStettingsState) => ({
      ...prevState,
      isPromptFocus: true
    }))
    // eslint-disable-next-line 
  }, [myEvent, sport, tournament, market, bbAndVsData?.vs, bbAndVsData?.bb]);

  useEffect(() => {
    setTimeout(() => {
      setLogReadyToSend(true)
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx?.trackContext])

  useEffect(() => {
    if (logReadyToSend) {
      logDropdownChange(context_id, market?.name, sport?.name, tournament?.name, myEvent?.name, myEvent?.id)
      setLogReadyToSend(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logReadyToSend])

  return (
    <div
      ref={chatRef}
      data-testid={'chat-component'}
      className="flex flex-1 flex-col h-max min-h-full border-r-2 border-l-2 overflow-hidden"
      style={{ borderColor: isSmallScreen ? "transparent" : "#333333" }}>
      <div className="relative flex-1 bg-[#111111]">
        <ChatNavHeader />
        {isFeedbackNudgingOpen && <FeedbackNudging />}
        <div className='flex flex-col justify-center items-center mt-3' >
          <ChatMainHeader />
          <div className='w-[95%] max-w-[1300px] mt-7 flex flex-col justify-center items-center'>
            <ChatSportsContext />
            <ChatPromptInput />
            <ChatOutput />
          </div>
        </div>
      </div>
      <ChatFooter />
    </div>
  );
};

export default Chat;
